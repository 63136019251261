import NavItem from './NavItem';
import MenuLinks from './config';
import PropTypes from 'prop-types';
import Logo from '../../../components/Logo';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MyAvatar from '../../../components/MyAvatar';
import Scrollbars from '../../../components/Scrollbars';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  Drawer,
  Hidden,
  Typography,
  ListSubheader,
} from '@material-ui/core';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    drawer: {
      [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      background: theme.palette.background.default,
    },
    subHeader: {
      ...theme.typography.overline,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    account: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 2.5),
      margin: theme.spacing(1, 2.5, 5),
      borderRadius: theme.shape.borderRadiusSm,
      background: theme.palette.grey[isLight ? 200 : 800],
    },
    doc: {
      padding: theme.spacing(2.5),
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: isLight
        ? alpha(theme.palette.primary.main, 0.08)
        : theme.palette.primary.lighter,
    },
    navList: {
      paddingBottom: '20px',
    },
  };
});

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;
  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderNavItems({
          pathname,
          level: level + 1,
          items: item.items,
        })}
      </NavItem>,
    ];
  } else {
    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
      />,
    ];
  }
  return array;
}

function renderNavItems({ items, pathname, accessList, subheader, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce((array, item) => {
        const isAccessToTabs =
          item.title === 'Team' ? true : accessList.includes(item.title);
        if (subheader === 'Management' && !isAccessToTabs) return array;
        return reduceChild({ array, item, pathname, level });
      }, [])}
    </List>
  );
}

const NavBar = React.memo(({ isOpenNav, onCloseNav }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const { me, userAccesToTabsNew } = useSelector((state) => state.user);

  const displayName = me ? me.firstName + ' ' + me.lastName : 'unknow';

  const accessList = useMemo(() => {
    if (userAccesToTabsNew) {
      return userAccesToTabsNew.map((item) => {
        switch (item) {
          case 'PROJECT_MANAGE':
            return 'Projects';
          case 'BRANCH_MANAGE':
            return 'Branches';
          case 'VIEW_WORKLOAD':
            return 'Time Sheets';
          case 'COMPANY_MANAGE':
            return 'Companies';
          case 'TENANT_SETTINGS':
            return 'Settings';
          default:
            return item;
        }
      });
    }
    return [];
  }, [userAccesToTabsNew]);

  useEffect(() => {
    if (isOpenNav && onCloseNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Box>

      <Link underline="none" component={RouterLink} to={'/'}>
        <div className={classes.account}>
          <MyAvatar />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" color="textPrimary">
              {displayName}
            </Typography>
          </Box>
        </div>
      </Link>
      <Scrollbars>
        <div className={classes.navList}>
          {MenuLinks.map((list) => {
            if (list.subheader === 'Management') {
              const isManagementFunction = list.items.filter((item) =>
                accessList.includes(item.title)
              );
              if (isManagementFunction && isManagementFunction.length > 0) {
                return (
                  <List
                    disablePadding
                    key={list.subheader}
                    subheader={
                      <ListSubheader
                        disableSticky
                        disableGutters
                        className={classes.subHeader}
                      >
                        {list.subheader}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      items: list.items,
                      pathname: pathname,
                      accessList,
                      subheader: list.subheader,
                    })}
                  </List>
                );
              } else return false;
            }
            return (
              <List
                disablePadding
                key={list.subheader}
                subheader={
                  <ListSubheader
                    disableSticky
                    disableGutters
                    className={classes.subHeader}
                  >
                    {list.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: list.items,
                  pathname: pathname,
                  accessList,
                  subheader: list.subheader,
                })}
              </List>
            );
          })}
        </div>
      </Scrollbars>
    </>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={isOpenNav}
          variant="temporary"
          onClose={onCloseNav}
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </nav>
  );
});

export default NavBar;

NavBar.propTypes = {
  onCloseNav: PropTypes.func,
  isOpenNav: PropTypes.bool,
};
