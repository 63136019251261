import React, { useEffect, memo, useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToSystemNew, getMeInformNew } from '../../redux/slices/user';
import { Box, CircularProgress } from '@material-ui/core';
import PermissionRequired from './PermissionRequired';

function AuthProtect({ children, ...rest }) {
  // save and compare user id to prevent additional rerender
  const userIdRef = useRef(null);
  const dispatch = useDispatch();
  const { me, isMeLoading, isAccessSystemLoading } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (!me) {
      dispatch(getMeInformNew());
    }
  }, [dispatch]);

  useEffect(() => {
    if (me && userIdRef.current !== me.id) {
      dispatch(getAccessToSystemNew());
      userIdRef.current = me.id;
    }
  }, [dispatch, me]);

  if (isMeLoading || isAccessSystemLoading) {
    return (
      <Box
        sx={{
          zIndex: 99,
          width: '100%',
          height: '100dvh',
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={32} thickness={2.4} />
      </Box>
    );
  }

  if (me.status === 404) {
    return (
      <Box
        sx={{
          zIndex: 99,
          position: 'absolute',
          width: '100%',
          height: '100dvh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PermissionRequired />
      </Box>
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        me ? (
          children
        ) : (
          <>
            {(location.pathname !== '/sign-in' ||
              location.pathname !== '/ui/sign-in') && (
              <Redirect
                to={{
                  pathname: '/sign-in',
                  state: { from: location },
                }}
              />
            )}
          </>
        )
      }
    />
  );
}

export default memo(AuthProtect);
