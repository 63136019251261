import { createSlice } from '@reduxjs/toolkit';
import { usersApi } from '../../api/usersApi';
import { enqueueSnackbar } from 'notistack';

// ----------------------------------------------------------------------

const initialState = {
  notesCategoriesList: [],
  notesList: [],
  isLoading: false,
};

const slice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setNotesCategoriesList(state, action) {
      state.isLoading = false;
      state.notesCategoriesList = action.payload;
    },
    setNotesList(state, action) {
      state.isLoading = false;
      state.notesList = action.payload;
    },

    addNoteInList(state, action) {
      state.notesList = [...state.notesList, action.payload];
    },

    removeNoteFromList(state, action) {
      state.notesList = state.notesList.filter(
        (note) => note.id !== action.payload
      );
    },
    updateNote(state, action) {
      const newNote = action.payload;
      state.notesList = state.notesList.map((item) => {
        if (item.id === newNote.id){
          return { ...item, content: newNote.content, pinned: newNote.pinned };
        }

        else return item;
      });
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNotesCategoriesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getNotesCategoriesList();
      let categories = response.data.body.items;
      dispatch(slice.actions.setNotesCategoriesList(categories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function getNotesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getNotesList();
      let notes = response.data.body.items;
      dispatch(slice.actions.setNotesList(notes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function createNote(memberId, categoryId, createdAt, content, pinned) {
  return async (dispatch) => {
    try {
      const response = await usersApi.createNote(
        categoryId,
        content,
        createdAt,
        memberId,
        pinned
      );

      if (response.data.status === 201 && response.data.errors === null) {
        const id = response.data.body.id;
        dispatch(
          slice.actions.addNoteInList({
            id,
            categoryId,
            content,
            createdAt,
            memberId,
            pinned,
          })
        );
        enqueueSnackbar('New note was successfully created!', {
          variant: 'success',
          preventDuplicate: false,
        });
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function removeNote(noteId) {
  return async (dispatch) => {
    try {
      const response = await usersApi.removeNote(noteId);

      if (response.data.status === 204 && response.data.errors === null) {
        dispatch(slice.actions.removeNoteFromList(noteId));
        enqueueSnackbar('The note was deleted!', {
          variant: 'success',
          preventDuplicate: false,
        });
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function updateNote(noteId, content, pinned) {
  return async (dispatch) => {
    try {
      const response = await usersApi.updateNote(noteId, content, pinned);

      if (response.data.status === 200 && response.data.errors === null) {
        const id = response.data.body.id;
        dispatch(
          slice.actions.updateNote({
            id,
            content,
            pinned,
          })
        );
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}
