import axiosInstance, { sessionId, mode, axiosNewInstance } from './axios';

// --------Projects----------
export const projectsApi = {
  getCurrentPeriod() {
    return axiosInstance
      .get(
        `memberReport/period/current${
          mode === 'development' ? '?sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getBranches(memberId) {
    return axiosInstance
      .get(
        `branch/managed?memberId=${memberId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getBranchesNew() {
    return axiosNewInstance
      .get('branch?fields=*')
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getBranch(branchIds) {
    return axiosInstance
      .get(
        `branch/get?branchIds=${branchIds}&fields=memberIds,projectIds${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getBranchNew(branchId) {
    return axiosNewInstance
      .get(`/branch/${branchId}/show?fields=*`)
      .then((response) => {
        return response;
      });
  },
  getReports(memberIds, branchIds, projectIds, periodFrom, periodTill) {
    return axiosInstance
      .get(
        `taskTimeEntry/report?memberIds=${memberIds}&branchIds=${branchIds}&projectIds=${projectIds}&periodFrom=${periodFrom}&periodTill=${periodTill}&fields=extendedDescription,status,value,projectId,memberId,typeOfWork${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getReportsExport(memberIds, branchIds, projectIds, periodFrom, periodTill) {
    return axiosInstance
      .get(
        `taskTimeEntry/report?memberIds=${memberIds}&branchIds=${branchIds}&projectIds=${projectIds}&periodFrom=${periodFrom}&periodTill=${periodTill}&exportFormat=xlsx&fields=extendedDescription,status,value,projectId,memberId,typeOfWork${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  projectCreate(branchId, name) {
    return axiosInstance
      .post(
        `project/create?branchId=${branchId}&name=${name}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  projectCreateNew(branchId, name) {
    return axiosNewInstance
      .post('/project/store?fields=Name,BranchId', {
        branchId: branchId,
        name: name,
      })
      .then((response) => {
        return response;
      });
  },
  projectUpdate(branchId, name, projectId, archived) {
    return axiosInstance
      .post(
        `project/update?branchId=${branchId}&name=${name}&projectId=${projectId}&archived=${archived}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  projectUpdateNew(branchId, name, projectId, archived) {
    return axiosNewInstance
      .put(`project/${projectId}/update?fields=Archived,Name,BranchId`, {
        archived: archived,
        branchId: branchId,
        name: name,
      })
      .then((response) => {
        return response;
      });
  },
  projectDelete(projectId) {
    return axiosInstance
      .post(
        `project/delete?projectId=${projectId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  projectDeleteNew(projectId) {
    return axiosNewInstance
      .post(`project/${projectId}/delete`)
      .then((response) => {
        return response;
      });
  },
  branchCreate(code, name) {
    return axiosInstance
      .post(
        `branch/create?code=${code}&name=${name}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  branchCreateNew(code, name, tenantId) {
    return axiosNewInstance.post('/branch/store?fields=Code,Name,TenantId', {
      code,
      name,
      tenantId,
    });
  },
  branchUpdateNew(branchId, name, code) {
    return axiosNewInstance
      .put(`/branch/${branchId}/update?fields=Name,Code`, {
        name: name,
        code: code,
      })
      .then((response) => {
        return response;
      });
  },
  branchDelete(branchId) {
    return axiosInstance
      .post(
        `branch/delete?branchId=${branchId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  branchDeleteNew(branchId) {
    return axiosNewInstance
      .post(`/branch/${branchId}/delete`)
      .then((response) => {
        return response;
      });
  },
};
