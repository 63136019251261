import axiosInstance, { axiosNewInstance, mode, sessionId } from './axios';
import { makeFields, searchBuilder } from '../utils/axiosUtils';

// --------Settings----------
export const settingsApi = {
  //----Country----
  countryList(queryObj) {
    return axiosInstance
      .get(`country?fields=code,name,id${searchBuilder(queryObj)}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  countryGet(countryId) {
    return axiosInstance
      .get(
        `country/get?countryIds=${countryId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  countryCreate(name, code, tenantId) {
    return axiosInstance
      .post(`country/store?fields=Name,Code,TenantId`, {
        name,
        code,
        tenantId,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  countryUpdate(countryId, name, code, tenantId) {
    return axiosInstance
      .put(`country/${countryId}/update?fields=*`, {
        name,
        code,
        tenantId,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  countryDelete(countryId) {
    return axiosInstance
      .post(`/country/${countryId}/delete`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //----Currency----
  currencyList(queryObj) {
    return axiosInstance
      .get(`currency?fields=*${searchBuilder(queryObj)}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  currencyGet(currencyIds) {
    return axiosInstance
      .get(
        `currency/get?currencyIds=${currencyIds}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  currencyCreate(code, tenantId) {
    return axiosInstance
      .post(`currency/store?fields=Code,TenantId`, {
        code,
        tenantId,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  currencyUpdate(currencyId, code, tenantId) {
    return axiosInstance
      .put(`currency/${currencyId}/update?fields=Code`, {
        code,
        tenantId,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  currencyDelete(currencyId) {
    return axiosInstance
      .post(`currency/${currencyId}/delete`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //----Bank----
  bankList(queryObj) {
    return axiosInstance
      .get(`bank?fields=*${searchBuilder(queryObj)}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankCreate(data) {
    return axiosInstance
      .post(`bank/store?fields=${makeFields(data)}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankUpdate(bankId, data) {
    return axiosInstance
      .put(`bank/${bankId}/update?fields=${makeFields(data)}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankDelete(bankId) {
    return axiosInstance
      .post(`bank/${bankId}/delete`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //----Positions----
  positionList() {
    return axiosInstance
      .post(
        `position/list${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  positionListNew() {
    return axiosNewInstance.get('position?fields=*').then((response) => {
      return response;
    });
  },
  positionCreate(data) {
    return axiosInstance
      .post(`position/store?fields=${makeFields(data)}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  positionUpdate(positionId, data) {
    return axiosInstance
      .put(`position/${positionId}/update?fields=${makeFields(data)}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  positionDelete(positionId) {
    return axiosInstance
      .post(`position/${positionId}/delete`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
};
