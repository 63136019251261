// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  // auth: '/auth',
  app: '',
};

export const PATH_APP = {
  root: ROOTS.app,
  account: {
    profile: path(ROOTS.app, '/profile'),
    settings: path(ROOTS.app, '/settings'),
  },
  workflow: {
    timeSheet: path(ROOTS.app, '/timesheet'),
  },
  office: {
    team: path(ROOTS.app, '/team'),
    birthdays: path(ROOTS.app, '/birthdays'),
    proposals: path(ROOTS.app, '/proposals'),
    door: path(ROOTS.app, '/solutions'),
  },
  management: {
    // root: path(ROOTS.app, '/management'),
    // user: {
    // team: path(ROOTS.app, '/management/team'),
    profile: path(ROOTS.app, '/management/profile/:memberId'),
    projects: path(ROOTS.app, '/management/projects'),
    project: path(ROOTS.app, '/management/projects/:projectId'),
    branches: path(ROOTS.app, '/management/branches'),
    branch: path(ROOTS.app, '/management/branches/:branchId'),
    settings: path(ROOTS.app, '/management/settings/:tab?'),
    companies: path(ROOTS.app, '/management/companies/:tab?/:companyId?/:action?'),
    team: path(ROOTS.app, '/management/team'),
    // },
  },
  reports: {
    performancesReport: path(ROOTS.app, '/reports/performance'),
    overtimes: path(ROOTS.app, '/reports/overtimes'),
    projectsReview: path(ROOTS.app, '/reports/projects-review'),
    teamSchedule: path(ROOTS.app, '/reports/overtimes/schedule/:memberId'),
    projectsReport: path(ROOTS.app, '/reports/projects-report'),
  },
  tests: {
    encription: path(ROOTS.app, '/tests/encryption'),
  },
};
