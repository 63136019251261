// ----------------------------------------------------------------------

export default function Paper({ theme }) {
  return {
    MuiPaper: {
      styleOverrides: {
        // rounded: { borderRadius: theme.shape.borderRadiusMd }
      },
    },
  };
}
