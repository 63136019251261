import qs from 'qs';
import axiosInstance, { mode, sessionId } from './axios';

// --------Tests----------
export const testsApi = {
  setEncrypNote(note) {
    return axiosInstance
      .post(
        `testEncryptedNote/set${
          mode === 'development' ? '?sessionId=' + sessionId : ''
        }`,
        qs.stringify({
          note: note,
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getEncrypNote(note) {
    return axiosInstance
      .get(
        `testEncryptedNote/get${
          mode === 'development' ? '?sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
};
