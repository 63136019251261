import { useDispatch } from 'react-redux';
import { signOutUrl } from '../../api/axios';
import { signOut } from '../../redux/slices/user';

import { Card, Box, Typography, Button } from '@material-ui/core';

const PermissionRequired = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    window.location.replace(signOutUrl);
    dispatch(signOut());
  };

  return (
    <Card
      sx={{
        maxWidth: '640px',
        width: '100%',
        padding: '30px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Typography variant="subtitle1">Permission Required</Typography>

        <Typography>
          Your account has not been granted the necessary permissions yet.
          <br />
          Please contact your HR manager for assistance.
        </Typography>

        <Button sx={{ alignSelf: 'flex-end' }} onClick={handleLogout}>
          LOGOUT
        </Button>
      </Box>
    </Card>
  );
};

export default PermissionRequired;
