import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LogoSvg } from '../assets/static/brand/uc-logo.svg';
// ----------------------------------------------------------------------

Logo.propTypes = {
  className: PropTypes.string,
};

function Logo({ className, ...other }) {
  return <LogoSvg height={50} width={50} className={className} {...other} />;
}

export default Logo;
