import { solutionsApi } from '../../api/solutionsApi';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isOpenDoor: false,
  error: null,
};

const slice = createSlice({
  name: 'solutions',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setOpenDoor(state, action) {
      state.isLoading = false;
      state.isOpenDoor = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function openDoor(myId) {
  return async (dispatch) => {
    dispatch(slice.actions.hasError(null));
    dispatch(slice.actions.setOpenDoor(false));
    try {
      const response = await solutionsApi.getOfficeId(myId);
      const officeId = response.data.object.items[0].officeId;

      const idsSolutions = await solutionsApi.getIdsSolution(officeId);
      const solutionId = idsSolutions.data.object.items[0].solutionIds[0];

      // const actionsAvailably = await solutionsApi.availableSolutionActions(solutionId);
      // console.log(actionsAvailably)

      const callAction = await solutionsApi.callAction(solutionId, 'open');

      if (callAction.data.status === 200 && callAction.data.object === null) {
        dispatch(slice.actions.setOpenDoor(true));
      } else throw new Error('Something is wrong, please try again');
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}
