import qs from 'qs';
import axiosInstance, { mode, sessionId } from './axios';
import { makeFields, searchBuilder } from '../utils/axiosUtils';

// --------Companies----------
export const companiesApi = {
  companyList(queryObj) {
    return axiosInstance
      .get(`company?fields=website,name,id${searchBuilder(queryObj)}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  searchCompanyList(queryObj) {
    return axiosInstance
      .get(`company/search?fields=website,name,id${searchBuilder(queryObj)}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  companyGet(companyId) {
    return axiosInstance
      .get(`company/${companyId}/show?fields=*`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  companyCreate(name, website, tenantId) {
    return axiosInstance
      .post(`company/store?fields=Name,Website,TenantId`, {
        name,
        website,
        tenantId,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  companyUpdate(companyId, name, website, tenantId) {
    return axiosInstance
      .put(`company/${companyId}/update?fields=Name,Website`, {
        name,
        website,
        tenantId,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  companyDelete(companyId) {
    return axiosInstance
      .post(`company/${companyId}/delete`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //---Legal Entity

  legalEntityList() {
    return axiosInstance
      .get(`legalEntity?fields=*`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  legalEntityGet(legalEntityId) {
    return axiosInstance
      .get(`legalEntity/${legalEntityId}/show?fields=*`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  legalEntityCreate(data) {
    return axiosInstance
      .post(`legalEntity/store?fields=${makeFields(data)}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  legalEntityUpdate(legalEntityId, data) {
    return axiosInstance
      .put(
        `legalEntity/${legalEntityId}/update?fields=${makeFields(data)}`,
        data
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  legalEntityDelete(legalEntityId) {
    return axiosInstance
      .post(`legalEntity/${legalEntityId}/delete`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //---Bank Account
  bankAccountGet(bankAccountId) {
    return axiosInstance
      .get(`bankAccount/${bankAccountId}/show?fields=*`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankAccountCreate(data) {
    return axiosInstance
      .post(`/bankAccount/store?fields=${makeFields(data)}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankAccountUpdate(bankAccountId, data) {
    return axiosInstance
      .put(
        `bankAccount/${bankAccountId}/update?fields=${makeFields(data)}`,
        data
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  bankAccountDelete(bankAccountId) {
    return axiosInstance
      .post(`bankAccount/${bankAccountId}/delete`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  //--------Invoices----------
  invoiceList(companyId) {
    return axiosInstance
      .get(`invoice?fields=*`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceGet(invoiceIds) {
    return axiosInstance
      .get(`invoice/${invoiceIds}/show?fields=*`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceCreate(data) {
    return axiosInstance
      .post(`invoice/store?fields=${makeFields(data)}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceUpdate(invoiceId, data) {
    return axiosInstance
      .put(`invoice/${invoiceId}/update?fields=${makeFields(data)}`, data)

      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceDelete(invoiceId) {
    return axiosInstance
      .post(`invoice/${invoiceId}/delete`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryGet(invoiceEntryIds) {
    return axiosInstance
      .get(
        `invoice/entry/get?invoiceEntryIds=${invoiceEntryIds}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryCreate(
    invoiceId,
    description,
    encRate,
    encAmount,
    encPrice,
    orderIndex
  ) {
    return axiosInstance
      .post(
        `invoice/entry/create?invoiceId=${invoiceId}&description=${description}&encRate=${encRate}&encAmount=${encAmount}&encPrice=${encPrice}&orderIndex=${orderIndex}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryCreateEncrypt(
    invoiceId,
    description,
    encRate,
    encAmount,
    encPrice,
    orderIndex
  ) {
    return axiosInstance
      .post(
        `invoice/entry/create?invoiceId=${invoiceId}&description=${description}&orderIndex=${orderIndex}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`,
        qs.stringify({
          encRate: encRate,
          encAmount: encAmount,
          encPrice: encPrice,
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryUpdate(
    invoiceEntryId,
    description,
    encRate,
    encAmount,
    encPrice,
    orderIndex
  ) {
    return axiosInstance
      .post(
        `invoice/entry/update?invoiceEntryId=${invoiceEntryId}&description=${description}&encRate=${encRate}&encAmount=${encAmount}&encPrice=${encPrice}&orderIndex=${orderIndex}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryUpdateEncrypt(
    invoiceEntryId,
    description,
    encRate,
    encAmount,
    encPrice,
    orderIndex
  ) {
    return axiosInstance
      .post(
        `invoice/entry/update?invoiceEntryId=${invoiceEntryId}&description=${description}&orderIndex=${orderIndex}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`,
        qs.stringify({
          encRate: encRate,
          encAmount: encAmount,
          encPrice: encPrice,
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  invoiceEntryDelete(invoiceEntryId) {
    return axiosInstance
      .post(
        `invoice/entry/delete?invoiceEntryId=${invoiceEntryId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
};
