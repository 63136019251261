import axiosInstance, { axiosNewInstance, mode, sessionId } from './axios';

// --------Access----------
export const accessApi = {
  getAccessType(managerId) {
    return axiosInstance
      .get(
        `access/type?managerId=${managerId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getAccessTypeNew() {
    return axiosNewInstance.get('accessTable/type');
  },
  accessListTemplate(request, managerId) {
    return axiosInstance
      .get(
        `${request}?managerId=${managerId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  accessListTemplateNew(request, managerId) {
    return axiosNewInstance.get(`${request}?managerId=${managerId}&fields=*`);
  },
  accessOptionsTemplate(request, managerId) {
    return axiosInstance
      .get(
        `${request}?memberId=${managerId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  accessOptionsTemplateNew(request) {
    return axiosNewInstance.get(`${request}?fields=*`);
  },
  accessMemberSetTemplate(
    request,
    managerId,
    memberId,
    accessId,
    features,
    paramRequest
  ) {
    return axiosInstance
      .get(
        `${request}?managerId=${managerId}&${paramRequest}=${memberId}&accessId=${accessId}&features=${features}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  accessMemberSetTemplateNew(
    request,
    managerId,
    memberId,
    features,
    accessId,
    paramRequest
  ) {
    return axiosNewInstance.post(`${request}`, {
      [paramRequest]: memberId,
      features: features,
      managerId: managerId,
      accessId: accessId || null,
    });
  },
  accessUnsetTemplate(request, accessId) {
    return axiosInstance
      .get(
        `${request}?accessId=${accessId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error || 'Something went wrong';
      });
  },
  accessUnsetTemplateNew(accessId) {
    return axiosNewInstance.post(`accessTable/${accessId}/unset`);
  },
  accessSystem(managerId) {
    return axiosInstance
      .get(
        `access/system?managerId=${managerId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  accessSystemNew() {
    return axiosNewInstance.get(`accessTable/system`);
  },
};
