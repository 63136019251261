import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Card, Typography } from '@material-ui/core';
import Logo from '../../components/Logo';
import { signInUrl } from '../../api/axios';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    marginTop: '1rem',
    width: 'fit-content',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30rem',
    maxHeight: '25rem',
    maxWidth: '80%',
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

// ----------------------------------------------------------------------

export function SignIn() {
  const classes = useStyles();

  const handleClick = () => {
    window.location.replace(signInUrl);
  };

  return (
    <Box className={classes.centered}>
      <Card
        className={classes.card}
        sx={{
          width: '20rem',
          height: '20rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Logo />
        <Typography variant="p">Please, sign in with Logto account</Typography>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleClick}
        >
          Sign in with Logto
        </Button>
      </Card>
    </Box>
  );
}

export default SignIn;
