import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MIconButton } from '../../../../@material-extend';
// import LockIcon from '@material-ui/icons/Lock';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import KeysManager from './KeysManager';
// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {},
  keyId: {
    marginTop: '5px',
    marginRight: '3px',
  },
}));

function Encryption() {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.theme);
  const [open, setOpen] = React.useState(false);
  const [activeKey, setActiveKey] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  const getKeysFromStorage = useCallback(() => {
    const dateNow = new Date();
    const keyDate = JSON.parse(localStorage.getItem('keyDate'));
    const keyDatePlusThirtyMinutes = addMinutes(new Date(keyDate), 30);

    const publicFromStorage = localStorage.getItem('pgp_public');
    const privateFromStorage = localStorage.getItem('pgp_private');

    if (publicFromStorage && privateFromStorage) {
      const withId_public_array = JSON.parse(publicFromStorage).split('_');
      const withId_private_array = JSON.parse(privateFromStorage).split('_');
      const pgp_public = withId_public_array[1];
      const pgp_private = withId_private_array[1];
      const id = withId_public_array[0];
      // setCurrentKey(id);

      if (dateNow <= keyDatePlusThirtyMinutes && pgp_public && pgp_private) {
        setActiveKey(id);
        return { pgp_public, pgp_private, id };
      } else {
        localStorage.removeItem('pgp_public');
        localStorage.removeItem('pgp_private');
        localStorage.removeItem('keyDate');
        return null;
      }
    }
  }, []);

  useEffect(() => {
    getKeysFromStorage();
  }, [getKeysFromStorage]);

  return (
    <>
      <MIconButton
        onClick={handleClickOpen}
        color={darkMode ? 'primary' : 'default'}
      >
        <Typography className={classes.keyId}>
          {activeKey && activeKey}
        </Typography>
        {!activeKey ? <NoEncryptionIcon /> : <LockOpenIcon />}
      </MIconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-encrypt"
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Encryption</DialogTitle>
        <DialogContent>
          <KeysManager setActiveKey={setActiveKey} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Encryption;
