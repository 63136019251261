export const makeFields = (data) =>
  Object.keys(data).reduce((acc, key) => {
    acc += `${key.replace(/\b\w/g, (c) => c.toUpperCase())},`;
    return acc;
  }, '');

export const searchBuilder = (queryObj) => {
  return Object.entries(queryObj).reduce((state, [queryKey, queryValue]) => {
    switch (queryKey) {
      case 'page':
        if (queryValue !== null && queryValue !== undefined) {
          state += `&${queryKey}=${queryValue}`;
        }
        return state;

      case 'per_page':
        if (queryValue !== null && queryValue !== undefined) {
          state += `&limit=${queryValue}`;
        }
        return state;

      case 'q':
        if (!!queryValue) {
          state += `&${queryKey}=${queryValue}`;
        }
        return state;

      case 'order_by':
        if (!!queryValue) {
          state += `&sort[0][field]=${queryValue}`;
        }
        return state;

      case 'order':
        if (!!queryValue && !!queryObj.order_by) {
          state += `&sort[0][order]=${queryValue}`;
        }
        return state;

      default:
        return state;
    }
  }, '');
};
