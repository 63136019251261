import axiosInstance, { sessionId, mode } from './axios';

// --------Solutions----------
export const solutionsApi = {
  getOfficeId(id) {
    return axiosInstance
      .get(
        `member/get?fields=officeId&memberIds=${id}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  getIdsSolution(officeId) {
    return axiosInstance
      .get(
        `office/get?fields=solutionIds&officeIds=${officeId}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  availableSolutionActions(id) {
    return axiosInstance
      .get(
        `solution/get?solutionIds=${id}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  callAction(id, action) {
    return axiosInstance
      .get(
        `solution/action/trigger?solutionId=${id}&actionName=${action}${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
};
