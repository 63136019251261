import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MAvatar } from '../@material-extend';
import { apiUrl } from '../api/axios';

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  className: PropTypes.string,
};

function MyAvatar({ className, ...other }) {
  const { me } = useSelector((state) => state.user);
  const [initial, setInitial] = useState('');

  useEffect(() => {
    if (me) {
      setInitial(me.firstName.charAt(0) + me.lastName.charAt(0));
    }
  }, [me]);

  const photoURL = me.avatar?.path;

  return (
    <MAvatar
      src={photoURL ? `${apiUrl}${photoURL}` : ''}
      color={'error'}
      className={className}
      {...other}
    >
      {initial}
    </MAvatar>
  );
}

export default MyAvatar;
