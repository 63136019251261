import React from 'react';
import { MIcon } from '../../../@material-extend';
import { PATH_APP } from '../../../routes/paths';
import userImg from '../../../assets/static/icons/navbar/ic_user.svg';
import mailImg from '../../../assets/static/icons/navbar/ic_mail.svg';
import pageImg from '../../../assets/static/icons/navbar/ic_page.svg';
import chartsImg from '../../../assets/static/icons/navbar/ic_charts.svg';
import elementsImg from '../../../assets/static/icons/navbar/ic_elements.svg';
import componentsImg from '../../../assets/static/icons/navbar/ic_components.svg';
import dashboardImg from '../../../assets/static/icons/navbar/ic_dashboard.svg';
import celebrationImg from '../../../assets/static/icons/navbar/ic_celebration.svg';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BusinessIcon from '@material-ui/icons/Business';
import CakeIcon from '@material-ui/icons/Cake';
import EventIcon from '@material-ui/icons/Event';
import GroupIcon from '@material-ui/icons/Group';
import LaptopIcon from '@material-ui/icons/Laptop';
import LayersIcon from '@material-ui/icons/Layers';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SettingsIcon from '@material-ui/icons/Settings';
import SpeedIcon from '@material-ui/icons/Speed';
import learnImg from '../../../assets/static/icons/navbar/ic_learn.svg';
// ----------------------------------------------------------------------

const path = (name) => `../../../assets/static/icons/navbar/${name}.svg`;

export const replaceDynamic = (pathStr) => {
  return pathStr.match(/^[^:]*/)[0];
};

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  birthday: <CakeIcon />,
  blog: <MIcon src={path('ic_blog')} />,
  branch: <AccountTreeIcon />,
  calendar: <EventIcon />,
  carousel: <MIcon src={path('ic_carousel')} />,
  charts: <MIcon src={chartsImg} />,
  celebration: <MIcon src={celebrationImg} />,
  chat: <MIcon src={path('ic_chat')} />,
  companies: <BusinessIcon />,
  components: <MIcon src={componentsImg} />,
  copy: <MIcon src={path('ic_copy')} />,
  dashboard: <MIcon src={dashboardImg} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={elementsImg} />,
  error: <MIcon src={path('ic_error')} />,
  laptop: <LaptopIcon />,
  learn: <MIcon src={learnImg} />,
  layers: <LayersIcon />,
  mail: <MIcon src={mailImg} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={pageImg} />,
  review: <RateReviewIcon />,
  settings: <SettingsIcon />,
  speed: <SpeedIcon />,
  team: <GroupIcon />,
  user: <MIcon src={userImg} />,
  upload: <MIcon src={path('ic_upload')} />,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  // Workflow
  // ----------------------------------------------------------------------
  {
    subheader: 'Workflow',
    items: [
      {
        title: 'TimeSheet',
        href: PATH_APP.workflow.timeSheet,
        icon: ICONS.calendar,
      },
    ],
  },
  // Office
  // ----------------------------------------------------------------------
  {
    subheader: 'Office',
    items: [
      {
        title: 'Team',
        href: PATH_APP.office.team,
        icon: ICONS.team,
      },
      {
        title: 'Birthdays',
        href: PATH_APP.office.birthdays,
        icon: ICONS.birthday,
      },
      // {
      //   title: 'Proposals',
      //   href: PATH_APP.office.proposals,
      //   icon: ICONS.mail
      // },
      {
        title: 'Solutions',
        href: PATH_APP.office.door,
        icon: ICONS.speed,
      },
    ],
  },
  // Management
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      {
        title: 'Team',
        href: PATH_APP.management.team,
        icon: ICONS.team,
      },
      {
        title: 'Projects',
        href: PATH_APP.management.projects,
        icon: ICONS.layers,
      },
      {
        title: 'Branches',
        href: PATH_APP.management.branches,
        icon: ICONS.branch,
      },
      {
        title: 'Companies',
        href: replaceDynamic(PATH_APP.management.companies),
        icon: ICONS.companies,
      },
      {
        title: 'Settings',
        href: replaceDynamic(PATH_APP.management.settings),
        icon: ICONS.settings,
      },
    ],
  },
  // Reports
  // {
  //   subheader: 'Reports',
  //   items: [
  //     {
  //       title: 'Performance',
  //       href: PATH_APP.reports.performancesReport,
  //       icon: ICONS.speed,
  //     },
  //     {
  //       title: 'Overtimes',
  //       href: PATH_APP.reports.overtimes,
  //       icon: ICONS.laptop,
  //     },
  //     {
  //       title: 'Projects Review',
  //       href: PATH_APP.reports.projectsReview,
  //       icon: ICONS.review,
  //     },
  //     {
  //       title: 'Projects Reports',
  //       href: PATH_APP.reports.projectsReport,
  //       icon: ICONS.layers,
  //     },
  //   ],
  // },
  // Tests
  // {
  //   subheader: 'Tests',
  //   items: [
  //     {
  //       title: 'Encryption',
  //       href: PATH_APP.tests.encription,
  //       icon: ICONS.dashboard
  //     },
  //   ]
  // },
  // MAIN DASHBOARD
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     {
  //       title: 'dashboard',
  //       icon: ICONS.dashboard,
  //       href: PATH_APP.main.root,
  //       items: [
  //         {
  //           title: 'app',
  //           href: PATH_APP.main.root
  //         },
  //         {
  //           title: 'e-commerce',
  //           href: PATH_APP.main.ecommerce
  //         },
  //         {
  //           title: 'analytics',
  //           href: PATH_APP.main.analytics
  //         }
  //       ]
  //     }
  //   ]
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       icon: ICONS.user,
  //       href: PATH_APP.management.user.root,
  //       items: [
  //         // {
  //         //   title: 'profile',
  //         //   href: PATH_APP.management.user.profile
  //         // },
  //         {
  //           title: 'cards',
  //           href: PATH_APP.management.user.cards
  //         },
  //         // {
  //         //   title: 'list',
  //         //   href: PATH_APP.management.user.list
  //         // },
  //         // {
  //         //   title: 'account',
  //         //   href: PATH_APP.management.user.account
  //         // }
  //       ]
  //     },

  //     // MANAGEMENT : E-COMMERCE
  //     // ----------------------------------------------------------------------
  //     // {
  //     //   title: 'e-commerce',
  //     //   icon: ICONS.cart,
  //     //   href: PATH_APP.management.eCommerce.root,
  //     //   items: [
  //     //     {
  //     //       title: 'shop',
  //     //       href: PATH_APP.management.eCommerce.products
  //     //     },
  //     //     {
  //     //       title: 'product',
  //     //       href: PATH_APP.management.eCommerce.productById
  //     //     },
  //     //     {
  //     //       title: 'list',
  //     //       href: PATH_APP.management.eCommerce.list
  //     //     },
  //     //     {
  //     //       title: 'checkout',
  //     //       href: PATH_APP.management.eCommerce.checkout
  //     //     },
  //     //     {
  //     //       title: 'invoice',
  //     //       href: PATH_APP.management.eCommerce.invoice
  //     //     }
  //     //   ]
  //     // },

  //     // MANAGEMENT : BLOG
  //     // ----------------------------------------------------------------------
  //     // {
  //     //   title: 'blog',
  //     //   icon: ICONS.blog,
  //     //   href: PATH_APP.management.blog.root,
  //     //   items: [
  //     //     {
  //     //       title: 'posts',
  //     //       href: PATH_APP.management.blog.root
  //     //     },
  //     //     {
  //     //       title: 'post',
  //     //       href: PATH_APP.management.blog.postById
  //     //     },
  //     //     {
  //     //       title: 'new post',
  //     //       href: PATH_APP.management.blog.newPost
  //     //     }
  //     //   ]
  //     // }
  //   ]
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       href: PATH_APP.app.mail.root,
  //       icon: ICONS.mail
  //     },
  //     {
  //       title: 'chat',
  //       href: PATH_APP.app.chat.root,
  //       icon: ICONS.chat
  //     },
  //     {
  //       title: 'calendar',
  //       href: PATH_APP.app.calendar,
  //       icon: ICONS.calendar
  //     }
  //   ]
  // },

  // PAGES
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'pages',
  //   items: [
  //     {
  //       title: 'auth',
  //       href: PATH_PAGE.auth.loginUnprotected,
  //       icon: ICONS.authenticator,
  //       items: [
  //         {
  //           title: 'login',
  //           href: PATH_PAGE.auth.loginUnprotected
  //         },
  //         {
  //           title: 'register',
  //           href: PATH_PAGE.auth.registerUnprotected
  //         },
  //         {
  //           title: 'reset password',
  //           href: PATH_PAGE.auth.resetPassword
  //         },
  //         {
  //           title: 'verify code',
  //           href: PATH_PAGE.auth.verify
  //         }
  //       ]
  //     },
  //     {
  //       title: 'errors & status',
  //       href: '/404',
  //       icon: ICONS.error,
  //       items: [
  //         {
  //           title: 'page 404',
  //           href: '/404'
  //         },
  //         {
  //           title: 'page 500',
  //           href: '/500'
  //         },
  //         {
  //           title: 'maintenance',
  //           href: PATH_PAGE.maintenance
  //         },
  //         {
  //           title: 'coming soon',
  //           href: PATH_PAGE.comingSoon
  //         }
  //       ]
  //     },
  //     {
  //       title: 'landing page',
  //       href: '/',
  //       icon: ICONS.page
  //     },
  //     {
  //       title: 'pricing',
  //       href: PATH_PAGE.pricing,
  //       icon: ICONS.page
  //     },
  //     {
  //       title: 'payment',
  //       href: PATH_PAGE.payment,
  //       icon: ICONS.page
  //     }
  //   ]
  // },

  // UI KIT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'UI kit',
  //   items: [
  //     {
  //       // FOUNDATIONS
  //       // ----------------------------------------------------------------------
  //       title: 'foundations',
  //       href: PATH_APP.foundations.root,
  //       icon: ICONS.elements,
  //       items: [
  //         {
  //           title: 'colors',
  //           href: PATH_APP.foundations.colors
  //         },
  //         {
  //           title: 'typography',
  //           href: PATH_APP.foundations.typography
  //         },
  //         {
  //           title: 'shadows',
  //           href: PATH_APP.foundations.shadows
  //         },
  //         {
  //           title: 'grid',
  //           href: PATH_APP.foundations.grid
  //         },
  //         {
  //           title: 'icons',
  //           href: PATH_APP.foundations.icons
  //         }
  //       ]
  //     },
  //     {
  //       // COMPONENTS
  //       // ----------------------------------------------------------------------
  //       title: 'components',
  //       href: PATH_APP.components.root,
  //       icon: ICONS.components,
  //       info: (
  //         <MLabel variant="filled" color="error">
  //           32+
  //         </MLabel>
  //       ),
  //       items: [
  //         {
  //           title: 'accordion',
  //           href: PATH_APP.components.accordion
  //         },
  //         {
  //           title: 'alert',
  //           href: PATH_APP.components.alert
  //         },
  //         {
  //           title: 'autocomplete',
  //           href: PATH_APP.components.autocomplete
  //         },
  //         {
  //           title: 'avatar',
  //           href: PATH_APP.components.avatar
  //         },
  //         {
  //           title: 'badge',
  //           href: PATH_APP.components.badge
  //         },
  //         {
  //           title: 'breadcrumbs',
  //           href: PATH_APP.components.breadcrumbs
  //         },
  //         {
  //           title: 'buttons',
  //           href: PATH_APP.components.buttons
  //         },
  //         {
  //           title: 'chip',
  //           href: PATH_APP.components.chip
  //         },
  //         {
  //           title: 'dialog',
  //           href: PATH_APP.components.dialog
  //         },
  //         {
  //           title: 'label',
  //           href: PATH_APP.components.label
  //         },
  //         {
  //           title: 'lists',
  //           href: PATH_APP.components.lists
  //         },
  //         {
  //           title: 'menu',
  //           href: PATH_APP.components.menu
  //         },
  //         {
  //           title: 'pagination',
  //           href: PATH_APP.components.pagination
  //         },
  //         {
  //           title: 'pickers',
  //           href: PATH_APP.components.pickers
  //         },
  //         {
  //           title: 'popover',
  //           href: PATH_APP.components.popover
  //         },
  //         {
  //           title: 'progress',
  //           href: PATH_APP.components.progress
  //         },
  //         {
  //           title: 'rating',
  //           href: PATH_APP.components.rating
  //         },
  //         {
  //           title: 'selection controls',
  //           href: PATH_APP.components.selectionControls
  //         },
  //         {
  //           title: 'slider',
  //           href: PATH_APP.components.slider
  //         },
  //         {
  //           title: 'snackbar',
  //           href: PATH_APP.components.snackbar
  //         },
  //         {
  //           title: 'stepper',
  //           href: PATH_APP.components.stepper
  //         },
  //         {
  //           title: 'tabs',
  //           href: PATH_APP.components.tabs
  //         },
  //         {
  //           title: 'table',
  //           href: PATH_APP.components.table
  //         },
  //         {
  //           title: 'text field',
  //           href: PATH_APP.components.textfield
  //         },
  //         {
  //           title: 'timeline',
  //           href: PATH_APP.components.timeline
  //         },
  //         {
  //           title: 'tooltip',
  //           href: PATH_APP.components.tooltip
  //         },
  //         {
  //           title: 'transfer list',
  //           href: PATH_APP.components.transferList
  //         },
  //         {
  //           title: 'tree view',
  //           href: PATH_APP.components.treeView
  //         }
  //       ]
  //     },

  //     // EXTRA COMPONENTS
  //     // ----------------------------------------------------------------------
  //     {
  //       title: 'charts',
  //       href: PATH_APP.components.chart.root,
  //       icon: ICONS.charts,
  //       items: [
  //         {
  //           title: 'apexcharts',
  //           href: PATH_APP.components.chart.apexcharts
  //         },
  //         {
  //           title: 'recharts',
  //           href: PATH_APP.components.chart.recharts
  //         }
  //       ]
  //     },
  //     {
  //       title: 'map',
  //       href: PATH_APP.components.map.root,
  //       icon: ICONS.map,
  //       items: [
  //         {
  //           title: 'google map',
  //           href: PATH_APP.components.map.google
  //         },
  //         {
  //           title: 'mapbox',
  //           href: PATH_APP.components.map.mapbox
  //         }
  //       ]
  //     },
  //     {
  //       title: 'editor',
  //       href: PATH_APP.components.editor,
  //       icon: ICONS.editor
  //     },
  //     {
  //       title: 'copy To clipboard',
  //       href: PATH_APP.components.copyToClipboard,
  //       icon: ICONS.copy
  //     },
  //     {
  //       title: 'upload',
  //       href: PATH_APP.components.upload,
  //       icon: ICONS.upload
  //     },
  //     {
  //       title: 'carousel',
  //       href: PATH_APP.components.carousel,
  //       icon: ICONS.carousel
  //     },
  //     {
  //       title: 'multi language',
  //       href: PATH_APP.components.multiLanguage,
  //       icon: ICONS.language
  //     }
  //   ]
  // }
];
