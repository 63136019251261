import { accessApi } from '../../api/accessApi';
import { usersApi } from '../../api/usersApi';
import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../helpers/constants';
import { enqueueSnackbar } from 'notistack';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isTeamSearchLoading: false,
  isMeLoading: true,
  isAccessSystemLoading: true,
  error: false,
  myProfile: null,
  users: [],
  usersBySearch: [],
  usersNew: [],
  userTeamList: [],
  userTeamListLoading: false,
  // notifications: null,
  me: null,
  memberProfile: null,
  memberProfileSomeFields: null,
  memberNew: null,
  projectList: [],
  notesCategoriesList: [],
  addRemoveProjectError: null,
  addRemoveProjectSuccess: null,
  createNoteError: null,
  createNoteSuccess: null,
  accessTypes: null,
  accessListNew: [],
  accessOptions: [],
  accessOptionsNew: [],
  featuresError: null,
  featuresForbidden: false,
  featuresSuccess: null,
  userAccesToTabs: null,
  usersBirthdayFields: [],
  officeList: null,
  members: null,
  favoritesMembers: null,
  accessListLoadingMap: {},
  accessOptionLoadingMap: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setTeamSearchLoading(state, action) {
      state.isTeamSearchLoading = action.payload;
    },

    setMeLoading(state, action) {
      state.isMeLoading = action.payload;
    },

    setAccessSystemLoading(state, action) {
      state.isAccessSystemLoading = action.payload;
    },

    startTeamListLoading(state) {
      state.userTeamListLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    getUsersSuccessNew(state, action) {
      state.isLoading = false;
      state.usersNew = action.payload;
    },

    setUsersBirthdayFields(state, action) {
      state.isLoading = false;
      state.usersBirthdayFields = action.payload ?? [];
    },

    getUsersBySearchSuccess(state, action) {
      state.usersBySearch = action.payload;
    },

    // GET MANAGE USERS
    setUserTeamList(state, action) {
      state.userTeamListLoading = false;
      state.userTeamList = action.payload;
    },

    // GET INFORM ABOUT ME
    setMeInform(state, action) {
      state.isMeLoading = false;
      state.me = action.payload;
    },

    //LOG OUT
    logOut(state, action) {
      return initialState;
    },
    // GET PROFILE
    setMemberProfile(state, action) {
      state.isLoading = false;
      state.memberProfile = action.payload;
    },
    setMemberProfileSomeFields(state, action) {
      state.isLoading = false;
      state.memberProfileSomeFields = action.payload;
    },
    setMemberProfileSomeFieldsNew(state, action) {
      state.isLoading = false;
      state.memberProfileSomeFieldsNew = action.payload;
    },
    setMemberNew(state, action) {
      state.isLoading = false;
      state.memberNew = action.payload;
    },
    setAccessListLoadingMap(state, action) {
      const { loadingStatus, nameOfType } = action.payload;
      state.accessListLoadingMap = {
        ...state.accessListLoadingMap,
        [nameOfType]: loadingStatus, //LoadingStatus
      };
    },
    setAccessOptionLoadingMap(state, action) {
      const { loadingStatus, nameOfType } = action.payload;
      state.accessOptionLoadingMap = {
        ...state.accessOptionLoadingMap,
        [nameOfType]: loadingStatus, //LoadingStatus
      };
    },

    setMemberUpdate(state, action) {
      const {
        memberId,
        firstName,
        lastName,
        birthDate,
        sinceDate,
        phoneNumber,
        positionId,
        isPartTime,
      } = action.payload;
      state.memberProfileSomeFields = {
        ...state.memberProfileSomeFields,
        id: memberId,
        firstName,
        lastName,
        birthDate,
        sinceDate,
        phoneNumber,
        positionId,
        isPartTime,
      };
    },
    setMemberUpdateNew(state, action) {
      const {
        memberId,
        firstName,
        lastName,
        birthDate,
        sinceDate,
        phoneNumber,
        positionId,
        isPartTime,
        isNdaSigned,
        ndaSignedAt,
      } = action.payload;
      state.memberProfileSomeFieldsNew = {
        ...state.memberProfileSomeFieldsNew,
        id: memberId,
        firstName,
        lastName,
        birthDate,
        sinceDate,
        phoneNumber,
        positionId,
        isPartTime,
        isNdaSigned,
        ndaSignedAt,
      };
    },
    setMeNew(state, action) {
      const {
        firstName,
        lastName,
        email,
        birthDate,
        telegram,
        skype,
        phoneNumber,
        photoURL,
        positionId,
      } = action.payload;
      state.me = {
        ...state.me,
        firstName,
        lastName,
        email,
        birthDate,
        telegram,
        skype,
        phoneNumber,
        photoURL,
        positionId,
      };
    },

    setAvatar(state, action) {
      state.me = { ...state.me, avatar: action.payload };
    },

    setProjectsList(state, action) {
      state.isLoading = false;
      state.projectList = action.payload;
    },
    setAddRemoveProjectError(state, action) {
      state.isLoading = false;
      state.addRemoveProjectError = action.payload;
    },
    setAddRemoveProjectSuccess(state, action) {
      state.isLoading = false;
      state.addRemoveProjectSuccess = action.payload;
    },
    setEditProfileError(state, action) {
      state.isLoading = false;
      state.editProfileError = action.payload;
    },
    setEditProfileSuccess(state, action) {
      state.isLoading = false;
      state.editProfileSuccess = action.payload;
    },
    addProjectInList(state, action) {
      state.memberProfileSomeFields = {
        ...state.memberProfileSomeFields,
        projectIds: [
          ...state.memberProfileSomeFields.projectIds,
          action.payload,
        ],
      };
    },
    removeProjectFromList(state, action) {
      const newFeatureList = state.memberProfileSomeFields.projectIds.filter(
        (item) => item !== action.payload
      );
      state.memberProfileSomeFields = {
        ...state.memberProfileSomeFields,
        projectIds: newFeatureList,
      };
    },
    addBranchInList(state, action) {
      state.memberProfileSomeFields = {
        ...state.memberProfileSomeFields,
        branchIds: [...state.memberProfileSomeFields.branchIds, action.payload],
      };
    },
    removeBranchFromList(state, action) {
      const newFeatureList = state.memberProfileSomeFields.branchIds.filter(
        (item) => item !== action.payload
      );
      state.memberProfileSomeFields = {
        ...state.memberProfileSomeFields,
        branchIds: newFeatureList,
      };
    },
    bindProjectToMember(state, action) {
      state.me = {
        ...state.me,
        projects: [...state.me.projects, action.payload],
      };
    },
    unbindProjectToMember(state, action) {
      const newProjectsList = state.me.projects.filter(
        (item) => item !== action.payload
      );
      state.me = {
        ...state.me,
        projects: newProjectsList,
      };
    },
    bindMemberToBranch(state, action) {
      state.me = {
        ...state.me,
        branchIds: [...state.me.branchIds, action.payload],
      };
    },
    unbindMemberToBranch(state, action) {
      const newBranchesList = state.me.branchIds.filter(
        (item) => item !== action.payload
      );
      state.me = {
        ...state.me,
        branchIds: newBranchesList,
      };
    },
    setAccessTypes(state, action) {
      // state.isLoading = false;
      state.accessTypes = action.payload;
    },
    setAccessTypesNew(state, action) {
      state.isLoading = false;
      state.accessTypesNew = action.payload;
    },
    setAccessList(state, action) {
      // state.isLoading = false;
      state.accessListNew = [...state.accessListNew, { ...action.payload }];
    },
    setAccessListNew(state, action) {
      // state.isLoading = false;
      state.accessListNew = [...state.accessListNew, { ...action.payload }];
    },
    resetAccessList(state, action) {
      state.accessList = [];
    },
    resetAccessListNew(state, action) {
      state.accessListNew = [];
    },
    setAccessOptionsList(state, action) {
      state.accessOptions = [...state.accessOptions, { ...action.payload }];
    },
    setAccessOptionsListNew(state, action) {
      state.accessOptionsNew = [
        ...state.accessOptionsNew,
        { ...action.payload },
      ];
    },
    resetAccessOptionsList(state, action) {
      state.accessOptions = [];
    },
    resetAccessOptionsListNew(state, action) {
      state.accessOptionsNew = [];
    },
    updateAccessList(state, action) {
      const updatedId = action.payload.id;
      const featuresArray = action.payload.features.split(',');
      const nameOfType = action.payload.nameOfType;
      const param = action.payload.param;
      const paramId = action.payload[param];

      const newList = state.accessList.map((item) => {
        const findElement = item.options.findIndex(
          (option) => option.id === updatedId
        );

        ///if already have item
        if (findElement !== -1) {
          const newOptions = item.options.map((option) => {
            if (option.id === updatedId)
              return { ...option, featureList: featuresArray };
            return option;
          });
          return { ...item, options: newOptions };
        }
        ///if create new item
        else if (findElement === -1) {
          if (item.name === nameOfType) {
            return {
              ...item,
              options: [
                ...item.options,
                { id: updatedId, [param]: paramId, featureList: featuresArray },
              ],
            };
          }
        }
        return item;
      });
      state.accessList = newList;
    },

    updateAccessListNew(state, action) {
      const updatedId = action.payload.id;
      // we use _localId only for data that has not yet been sent to the server.
      // we need not make a duplicate on the server after the update, but if there is no data yet, then we cannot use the updatedId
      const _localId = action.payload._localId;
      const featuresArray = action.payload.features;
      const nameOfType = action.payload.nameOfType;
      const param = action.payload.param;
      const paramId = action.payload[param];

      const newList = state.accessListNew.map((item) => {
        const findElement = item.options.findIndex(
          (option) =>
            (!!updatedId && option.id === updatedId) ||
            (!!_localId && option._localId === _localId)
        );

        ///if already have item
        if (findElement !== -1) {
          const newOptions = item.options.map((option) => {
            if (option.id === updatedId || option._localId === _localId) {
              return { ...option, featureList: featuresArray, id: updatedId };
            }

            return option;
          });
          return { ...item, options: newOptions };
        }
        ///if create new item
        else if (findElement === -1 && item.name === nameOfType) {
          return {
            ...item,
            options: [
              ...item.options,
              {
                id: updatedId,
                [param]: paramId,
                featureList: featuresArray,
                _localId,
              },
            ],
          };
        }
        return item;
      });

      state.accessListNew = newList;
    },

    deleteOption(state, action) {
      const updatedId = action.payload.accessId;
      const newList = state.accessListNew.map((item) => {
        const filteredOptions = item.options.filter(
          (option) => option.id !== updatedId
        );

        return { ...item, options: filteredOptions };
      });
      state.accessListNew = newList;
    },
    deleteUnsavedOption(state, action) {
      const _localId = action.payload._localId;
      const newList = state.accessListNew.map((item) => {
        const filteredOptions = item.options.filter(
          (option) => option._localId !== _localId
        );

        return { ...item, options: filteredOptions };
      });
      state.accessListNew = newList;
    },

    setFeaturesError(state, action) {
      state.isLoading = false;
      state.featuresError = action.payload;
    },
    setFeaturesSuccess(state, action) {
      state.isLoading = false;
      state.featuresSuccess = action.payload;
    },
    setFeaturesForbidden(state, action) {
      state.isLoading = false;
      state.featuresForbidden = action.payload;
    },
    //----Acces To Tabs----
    setUserAccesToTabs(state, action) {
      state.userAccesToTabs = action.payload;
    },
    setUserAccesToTabsNew(state, action) {
      state.userAccesToTabsNew = action.payload;
    },
    //----Office----
    setOfficeList(state, action) {
      state.officeList = action.payload;
    },
    setMembers(state, action) {
      state.members = action.payload;
    },
    setFavoritesMembers(state, action) {
      state.favoritesMembers = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCreateNoteSuccess,
  setEditProfileError,
  setEditProfileSuccess,
  setRemoveNoteError,
  setRemoveNoteSuccess,
  setFeaturesError,
  setFeaturesSuccess,
  setAccessTypes,
  resetAccessListNew,
  resetAccessOptionsListNew,
} = slice.actions;

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getUsers();
      let usersData = response.data.object.items;
      dispatch(slice.actions.getUsersSuccess(usersData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersNew() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getUsersNew();
      let usersData = response.data.body.items;
      dispatch(slice.actions.getUsersSuccessNew(usersData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersBirthdayFields() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getUsersBirthdayFields();
      let usersData = response.data.body.items;
      dispatch(slice.actions.setUsersBirthdayFields(usersData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersBySearch(query) {
  return async (dispatch) => {
    dispatch(slice.actions.setTeamSearchLoading(true));
    try {
      const response = await usersApi.getUsersBySearch(query);
      let usersData = response.data.body.items;
      dispatch(slice.actions.getUsersBySearchSuccess(usersData));
      dispatch(slice.actions.setTeamSearchLoading(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setTeamSearchLoading(false));
    }
  };
}

export function getMeInformNew() {
  return async (dispatch) => {
    dispatch(slice.actions.setMeLoading(true));

    try {
      const response = await usersApi.getMeNew();
      let me = response.data.body;

      if (response.data.status_code === 'NOT_FOUND') {
        me = { status: 404 };
      }

      dispatch(slice.actions.setMeInform(me));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setMeLoading(false));
    }
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch(slice.actions.logOut());
  };
}

export function getUserTeamList() {
  return async (dispatch) => {
    dispatch(slice.actions.startTeamListLoading());
    try {
      const response = await usersApi.getMyTeam();
      let userData = response.data.object.items;
      dispatch(slice.actions.setUserTeamList(userData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMemberProfile(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getMember(id);
      let memberData = response.data.object.items[0];
      dispatch(slice.actions.setMemberProfile(memberData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMembers(ids, fields) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getMembers(ids, fields);
      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        let memberData = response.data.object.items;
        dispatch(slice.actions.setMembers(memberData));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFavoritesMembers(ids, fields) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getMembers(ids, fields);
      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        let memberData = response.data.object.items;
        dispatch(slice.actions.setFavoritesMembers(memberData));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProjectList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getProjectsList();
      let projects = response.data.object.items;
      dispatch(slice.actions.setProjectsList(projects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bindProjectToMember(memberId, projectId) {
  return async (dispatch) => {
    try {
      await usersApi.bindProjectToMember(memberId, projectId);
      dispatch(slice.actions.bindProjectToMember(projectId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unbindProjectToMember(memberId, projectId) {
  return async (dispatch) => {
    try {
      await usersApi.unbindProjectToMember(memberId, projectId);
      dispatch(slice.actions.unbindProjectToMember(projectId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bindMemberToBranch(memberId, branchId) {
  return async (dispatch) => {
    try {
      await usersApi.bindMemberToBranch(memberId, branchId);
      dispatch(slice.actions.bindMemberToBranch(branchId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unbindMemberToBranch(memberId, branchId) {
  return async (dispatch) => {
    try {
      await usersApi.unbindMemberToBranch(memberId, branchId);
      dispatch(slice.actions.unbindMemberToBranch(branchId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addProject(memberId, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveProjectError(null));
    dispatch(slice.actions.setAddRemoveProjectSuccess(null));

    try {
      const response = await usersApi.addProject(memberId, projectId);
      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        // const id = response.data.object.item;
        dispatch(slice.actions.addProjectInList(projectId));
        dispatch(slice.actions.setAddRemoveProjectSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveProjectError(error.message));
    }
  };
}

export function removeProject(memberId, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveProjectError(null));
    dispatch(slice.actions.setAddRemoveProjectSuccess(null));

    try {
      const response = await usersApi.removeProject(memberId, projectId);
      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        dispatch(slice.actions.removeProjectFromList(projectId));
        dispatch(slice.actions.setAddRemoveProjectSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveProjectError(error.message));
    }
  };
}

export function addBranch(memberId, branchId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveProjectError(null));
    dispatch(slice.actions.setAddRemoveProjectSuccess(null));

    try {
      const response = await usersApi.addBranch(memberId, branchId);
      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        // const id = response.data.object.item;
        dispatch(slice.actions.addBranchInList(branchId));
        dispatch(slice.actions.setAddRemoveProjectSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveProjectError(error.message));
    }
  };
}

export function removeBranch(memberId, branchId) {
  return async (dispatch) => {
    dispatch(slice.actions.setAddRemoveProjectError(null));
    dispatch(slice.actions.setAddRemoveProjectSuccess(null));

    try {
      const response = await usersApi.removeBranch(memberId, branchId);
      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        dispatch(slice.actions.removeBranchFromList(branchId));
        dispatch(slice.actions.setAddRemoveProjectSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setAddRemoveProjectError(error.message));
    }
  };
}

export function getMemberSomeFields(id, fields) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getMemberSomeFields(id, fields);
      let memberData = response.data.object.items[0];
      dispatch(slice.actions.setMemberProfileSomeFields(memberData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMemberProfileSomeFieldsNew(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getMemberProfileSomeFieldsNew(id);
      let memberData = response.data.body;
      dispatch(slice.actions.setMemberProfileSomeFieldsNew(memberData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMemberNew(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await usersApi.getMemberNew(id);
      let memberData = response.data.body;
      dispatch(slice.actions.setMemberNew(memberData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function memberUpdate(
  memberId,
  firstName,
  lastName,
  birthDate,
  sinceDate,
  phoneNumber,
  positionId,
  isPartTime
) {
  return async (dispatch) => {
    try {
      const response = await usersApi.memberUpdate(
        memberId,
        firstName,
        lastName,
        birthDate,
        sinceDate,
        phoneNumber,
        positionId,
        isPartTime
      );
      // console.log(response.data);
      if (response.data.status === 200 && !response.data.errors?.length) {
        // let memberData = response.data.object.items[0];
        dispatch(
          slice.actions.setMemberUpdate({
            memberId,
            firstName,
            lastName,
            birthDate,
            sinceDate,
            phoneNumber,
            positionId,
            isPartTime,
          })
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function memberUpdateNew(
  memberId,
  firstName,
  lastName,
  birthDate,
  sinceDate,
  phoneNumber,
  positionId,
  isPartTime,
  isNdaSigned,
  ndaSignedAt
) {
  return async (dispatch) => {
    dispatch(slice.actions.setEditProfileError(null));
    dispatch(slice.actions.setEditProfileSuccess(null));
    try {
      const response = await usersApi.memberUpdateNew(
        memberId,
        firstName,
        lastName,
        birthDate,
        sinceDate,
        phoneNumber,
        positionId,
        isPartTime,
        isNdaSigned,
        ndaSignedAt
      );

      if (response.data.status === 200 && response.data.errors === null) {
        dispatch(
          slice.actions.setMemberUpdateNew({
            memberId,
            firstName,
            lastName,
            birthDate,
            sinceDate,
            phoneNumber,
            positionId,
            isPartTime,
            isNdaSigned,
            ndaSignedAt,
          })
        );

        dispatch(slice.actions.setEditProfileSuccess(true));

        enqueueSnackbar('Profile data was successfully changed!', {
          variant: 'success',
          preventDuplicate: false,
        });
      }
    } catch (error) {
      dispatch(slice.actions.setEditProfileError(error.message));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function meUpdate(
  firstName,
  lastName,
  email,
  birthDate,
  phoneNumber,
  photoURL
) {
  return async (dispatch) => {
    dispatch(slice.actions.setEditProfileError(null));
    dispatch(slice.actions.setEditProfileSuccess(null));
    try {
      const response = await usersApi.updateMe(
        firstName,
        lastName,
        email,
        birthDate,
        phoneNumber
      );
      if (response.data.status === 200 && response.data.errors === null) {
        dispatch(
          slice.actions.setMeNew({
            firstName,
            lastName,
            email,
            birthDate,
            phoneNumber,
            photoURL,
          })
        );
        dispatch(slice.actions.setEditProfileSuccess(true));

        enqueueSnackbar('Profile data was successfully changed!', {
          variant: 'success',
          preventDuplicate: false,
        });
      } else throw Error(response.data.errors);
    } catch (error) {
      dispatch(slice.actions.setEditProfileError(error.message));

      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}
export function setAvatar(url) {
  return (dispatch) => {
    dispatch(
      slice.actions.setAvatar({
        name: 'avatar',
        path: url,
      })
    );
  };
}

export function resetMemberInfo() {
  return (dispatch) => {
    dispatch(slice.actions.setMemberProfileSomeFields(null));
  };
}

export function resetMemberInfoNew() {
  return (dispatch) => {
    dispatch(slice.actions.setMemberProfileSomeFieldsNew(null));
  };
}

////////////---Access---//////////

export function getAccessType(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await accessApi.getAccessType(id);
      let data = response.data.object.item.types;
      dispatch(slice.actions.setAccessTypes(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccessTypeNew(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await accessApi.getAccessTypeNew();
      let data = response.data.body.items;
      dispatch(slice.actions.setAccessTypesNew(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccessListNew(request, managerId, nameOfType) {
  return async (dispatch) => {
    dispatch(
      slice.actions.setAccessListLoadingMap({
        nameOfType,
        loadingStatus: LoadingStatus.LOADING,
      })
    );

    try {
      const response = await accessApi.accessListTemplateNew(
        request,
        managerId
      );
      if (response.data.status === 200 && response.data.errors === null) {
        let data = response.data.body.items;
        dispatch(
          slice.actions.setAccessListNew({ name: nameOfType, options: data })
        );
        dispatch(
          slice.actions.setAccessListLoadingMap({
            nameOfType,
            loadingStatus: LoadingStatus.SUCCESS,
          })
        );
      } else if (response.data.status_code === 'FORBIDDEN') {
        dispatch(slice.actions.setFeaturesForbidden(true));
        dispatch(
          slice.actions.setAccessListLoadingMap({
            nameOfType,
            loadingStatus: LoadingStatus.ERROR,
          })
        );
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
        slice.actions.setAccessListLoadingMap({
          nameOfType,
          loadingStatus: LoadingStatus.ERROR,
        })
      );
    }
  };
}

export function getAccessOptionsNew(request, nameOfType) {
  return async (dispatch) => {
    dispatch(
      slice.actions.setAccessOptionLoadingMap({
        nameOfType,
        loadingStatus: LoadingStatus.LOADING,
      })
    );

    try {
      const response = await accessApi.accessOptionsTemplateNew(request);
      if (response.data.status === 200 && response.data.errors === null) {
        let data = response.data.body.items;
        dispatch(
          slice.actions.setAccessOptionsListNew({
            name: nameOfType,
            options: data,
          })
        );
        dispatch(
          slice.actions.setAccessOptionLoadingMap({
            nameOfType,
            loadingStatus: LoadingStatus.SUCCESS,
          })
        );
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
        slice.actions.setAccessOptionLoadingMap({
          nameOfType,
          loadingStatus: LoadingStatus.ERROR,
        })
      );
    }
  };
}

export function accessMemberSet(
  request,
  managerId,
  memberId,
  accessId,
  features,
  paramRequest,
  nameOfType
) {
  return async (dispatch) => {
    dispatch(slice.actions.setFeaturesError(null));
    dispatch(slice.actions.setFeaturesSuccess(null));

    try {
      const response = await accessApi.accessMemberSetTemplate(
        request,
        managerId,
        memberId,
        accessId,
        features,
        paramRequest
      );

      if (response.data.status === 200 && response.data.code.name === 'NONE') {
        let data = response.data.object.item;
        dispatch(
          slice.actions.updateAccessList({
            id: data,
            features,
            nameOfType,
            [paramRequest]: memberId,
            param: paramRequest,
          })
        );
        dispatch(slice.actions.setFeaturesSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setFeaturesError(error.message));
    }
  };
}

export function accessMemberSetNew(
  request,
  managerId,
  memberId,
  features,
  accessId,
  paramRequest,
  nameOfType,
  _localId
) {
  return async (dispatch) => {
    dispatch(slice.actions.setFeaturesError(null));
    dispatch(slice.actions.setFeaturesSuccess(null));

    try {
      const response = await accessApi.accessMemberSetTemplateNew(
        request,
        managerId,
        memberId,
        features,
        accessId,
        paramRequest
      );

      if (
        response.data.status === 200 &&
        response.data.status_code === 'SUCCESS'
      ) {
        dispatch(
          slice.actions.updateAccessListNew({
            id: accessId || response.data.body.id,
            features,
            nameOfType,
            [paramRequest]: memberId,
            param: paramRequest,
            _localId,
          })
        );
        dispatch(slice.actions.setFeaturesSuccess(true));
      } else if (response.data.status_code === 'FORBIDDEN') {
        dispatch(slice.actions.setFeaturesForbidden(true));
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.setFeaturesError(error.message));
    }
  };
}

export function updateAccessList_local(body) {
  return (dispatch) => {
    dispatch(slice.actions.updateAccessListNew(body));
  };
}

export function accessUnset_local(_localId) {
  return (dispatch) => {
    dispatch(slice.actions.deleteUnsavedOption({ _localId }));
  };
}

export function accessUnset(request, accessId) {
  return async (dispatch) => {
    dispatch(slice.actions.setFeaturesError(null));
    dispatch(slice.actions.setFeaturesSuccess(null));

    try {
      const response = await accessApi.accessUnsetTemplate(request, accessId);
      if (
        response.data &&
        response.data.status === 200 &&
        response.data.code.name === 'NONE'
      ) {
        dispatch(slice.actions.deleteOption(accessId));
        dispatch(slice.actions.setFeaturesSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setFeaturesError(error.message));
    }
  };
}

export function accessUnsetNew(accessId, nameOfType) {
  return async (dispatch) => {
    dispatch(slice.actions.setFeaturesError(null));
    dispatch(slice.actions.setFeaturesSuccess(null));

    try {
      const response = await accessApi.accessUnsetTemplateNew(accessId);
      if (
        response.data &&
        response.data.status === 204 &&
        response.data.status_code === 'DELETED'
      ) {
        dispatch(slice.actions.deleteOption({ accessId, nameOfType }));
        dispatch(slice.actions.setFeaturesSuccess(true));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.setFeaturesError(error.message));
    }
  };
}

export function getAccessToSystemNew() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setAccessSystemLoading(true));
      const response = await accessApi.accessSystemNew();
      if (
        response.data &&
        response.data.status === 200 &&
        response.data.status_code === 'SUCCESS'
      ) {
        let data = response.data.body.featureList;
        dispatch(slice.actions.setUserAccesToTabsNew(data));
        dispatch(slice.actions.setAccessSystemLoading(false));
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.setFeaturesError(error.message));
      dispatch(slice.actions.setAccessSystemLoading(false));
    }
  };
}

//office

export function getOfficeList() {
  return async (dispatch) => {
    try {
      const response = await usersApi.getOfficeList();
      // console.log(response)
      if (
        response.data &&
        response.data.status === 200 &&
        response.data.code.name === 'NONE'
      ) {
        let data = response.data.object.items;
        dispatch(slice.actions.setOfficeList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOfficeListNew() {
  return async (dispatch) => {
    try {
      const response = await usersApi.getOfficeListNew();
      if (
        response.data &&
        response.data.status === 200 &&
        response.data.errors === null
      ) {
        let data = response.data.body.items;
        dispatch(slice.actions.setOfficeList(data));
      } else throw new Error(response.data.status_code);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
