import { companiesApi } from '../../api/companiesApi';
import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';

// ----------------------------------------------------------------------

const initialState = {
  error: false,
  companiesList: null,
  companiesPagination: {
    offset: 0,
    per_page: 50,
    total: 1,
  },
  companyInfo: null,
  isCompanyInfoLoading: false,
  legalEntityInfoArray: [],
  bankAccounts: [],
  invoiceList: null,
  openInvoice: null,
  invoiceEntry: [],
};

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    setIsCompanyInfoLoading(state, action) {
      state.isCompanyInfoLoading = action.payload;
    },
    setCompaniesList(state, action) {
      state.companiesList = action.payload;
    },
    setCompaniesPagination(state, action) {
      state.companiesPagination = action.payload;
    },
    setCompanyInfo(state, action) {
      state.companyInfo = action.payload;
    },

    addInCompanyList(state, action) {
      state.companiesList = [...state.companiesList, action.payload];
      state.companyInfo = action.payload;
    },
    updateCompany(state, action) {
      const newCompany = action.payload;
      state.companiesList = state.companiesList.map((item) => {
        if (item.id === newCompany.id)
          return {
            ...item,
            name: newCompany.name,
            website: newCompany.website,
          };
        return item;
      });
      state.companyInfo = {
        ...state.companyInfo,
        name: action.payload.name,
        website: action.payload.website,
      };
    },
    deleteCompany(state, action) {
      const id = action.payload;
      state.companiesList = state.companiesList.filter(
        (item) => item.id !== id
      );
      state.companyInfo = null;
    },
    ///---Legal Entity---
    setLegalEntityInfo(state, action) {
      state.legalEntityInfoArray = [
        ...state.legalEntityInfoArray,
        action.payload,
      ];
    },
    resetLegalEntityInfo(state, action) {
      state.legalEntityInfoArray = [];
    },
    deleteLegalEntityInfo(state, action) {
      const id = action.payload;
      state.legalEntityInfoArray = state.legalEntityInfoArray.filter(
        (item) => item.id !== id
      );
    },
    updateLegalEntityInfo(state, action) {
      const newLegalEntity = action.payload;
      state.legalEntityInfoArray = state.legalEntityInfoArray.map((item) => {
        if (item.id === newLegalEntity.id)
          return {
            ...item,
            ...newLegalEntity,
          };
        return item;
      });
    },
    setLegalEntityInfoArray(state, action) {
      state.legalEntityInfoArray = action.payload;
    },
    //---Bank Account---
    setBankAccounts(state, action) {
      state.bankAccounts = action.payload;
    },
    //---Bank Account---
    pushBankAccountsWithoutCompany(state, action) {
      const newBankAccount = action.payload;

      const index = state.bankAccounts.findIndex(
        (item) => item.id === newBankAccount.id
      );

      if (index !== -1) {
        state.bankAccounts[index] = newBankAccount;
      } else {
        state.bankAccounts.push(newBankAccount);
      }
      state.bankAccounts = [...state.bankAccounts];
    },
    addInBankAccounts(state, action) {
      state.bankAccounts = [...state.bankAccounts, action.payload];
      state.companyInfo = {
        ...state.companyInfo,
        bankAccounts: [...state.companyInfo.bankAccounts, action.payload.id],
      };
    },
    updateBankAccounts(state, action) {
      const newBankAccounts = action.payload;
      state.bankAccounts = state.bankAccounts.map((item) => {
        if (item.id === newBankAccounts.id) return newBankAccounts;
        return item;
      });
    },
    deleteBankAccount(state, action) {
      const id = action.payload;
      state.bankAccounts = state.bankAccounts.filter((item) => item.id !== id);
    },
    //---Invoice---
    setInvoiceList(state, action) {
      state.invoiceList = action.payload;
    },
    setOpenInvoice(state, action) {
      state.openInvoice = action.payload;
    },
    addInInvoiceList(state, action) {
      state.invoiceList = [...state.invoiceList, action.payload];
    },
    updateInvoice(state, action) {
      const newInvoice = action.payload;
      state.invoiceList = state.invoiceList.map((item) => {
        if (item.id === newInvoice.id) return newInvoice;
        return item;
      });
      const { fromId, toId, bankAccountId, note, number, date } =
        action.payload;
      state.openInvoice = {
        ...state.openInvoice,
        fromId,
        toId,
        bankAccountId,
        note,
        number,
        date,
      };
    },
    deleteInvoice(state, action) {
      const id = action.payload;
      state.invoiceList = state.invoiceList.filter((item) => item.id !== id);
      state.openInvoice = null;
    },
    setInvoiceEntry(state, action) {
      state.invoiceEntry = action.payload;
    },
    addInInvoiceEntry(state, action) {
      state.invoiceEntry = [...state.invoiceEntry, action.payload];
    },
    updateInvoiceEntry(state, action) {
      const newInvoiceEntry = action.payload;
      state.invoiceEntry = state.invoiceEntry.map((item) => {
        if (item.id === newInvoiceEntry.id) return newInvoiceEntry;
        return item;
      });
    },
    updateInvoiceEntryDragNDrop(state, action) {
      // console.log(action.payload)
      state.invoiceEntry = action.payload;
    },
    deleteInvoiceEntry(state, action) {
      const id = action.payload;
      state.invoiceEntry = state.invoiceEntry.filter((item) => item.id !== id);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCompaniesList,
  setCompaniesPagination,
  setCompanyInfo,
  resetLegalEntityInfo,
  setBankAccounts,
  setOpenInvoice,
  setInvoiceList,
  updateInvoiceEntryDragNDrop,
} = slice.actions;

// ---------------Companies---------------------------------

export function getCompaniesList(params) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.companyList(params);
      if (response.data.status === 200 && !response.data.errors?.length) {
        const { items, offset, per_page, total } = response.data.body;

        dispatch(setCompaniesList(items));
        dispatch(
          setCompaniesPagination({
            offset,
            per_page,
            total,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function searchCompaniesList(params) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.searchCompanyList(params);
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.body.items;
        dispatch(slice.actions.setCompaniesList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function companyGet(companyId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setIsCompanyInfoLoading(true));
      const response = await companiesApi.companyGet(companyId);
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.body;
        dispatch(slice.actions.setCompanyInfo(data));
        dispatch(slice.actions.setIsCompanyInfoLoading(false));
        return true;
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
      dispatch(slice.actions.setIsCompanyInfoLoading(false));
      return false;
    }
  };
}

export function companyCreate(name, website) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await companiesApi.companyCreate(
        name,
        website,
        state.user.me.tenantId
      );
      if (response.data.status === 201 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(
          slice.actions.addInCompanyList({
            id,
            name,
            website,
            bankAccount: [],
          })
        );
        enqueueSnackbar('Company was successfully created!', {
          variant: 'success',
          preventDuplicate: false,
        });
        return id;
      } else throw new Error(response.data.status_code);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
      return null;
    }
  };
}

export function companyDelete(companyId, companyName) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.companyDelete(companyId);
      if (response.data.status === 204 && !response.data.errors?.length) {
        dispatch(slice.actions.deleteCompany(companyId));
        enqueueSnackbar(`${companyName} company deleted!`, {
          variant: 'success',
          preventDuplicate: false,
        });
        return true;
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
      return false;
    }
  };
}

export function companyUpdate(companyId, name, website) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.companyUpdate(
        companyId,
        name,
        website
      );
      if (response.data.status === 200 && !response.data.errors?.length) {
        dispatch(slice.actions.updateCompany({ id: companyId, name, website }));
        enqueueSnackbar('Company was successfully update!', {
          variant: 'success',
          preventDuplicate: false,
        });
        return companyId;
      } else throw new Error(response.data.code.name);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
      return false;
    }
  };
}

// ---------------Legal Entity---------------------------------

export function legalEntityList() {
  return async (dispatch) => {
    try {
      const response = await companiesApi.legalEntityList();
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.body.items;
        dispatch(slice.actions.setLegalEntityInfoArray(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function legalEntityGet(legalEntityId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.legalEntityGet(legalEntityId);
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.object.item;
        dispatch(slice.actions.setLegalEntityInfo(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function legalEntityCreate(data) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await companiesApi.legalEntityCreate({
        ...data,
        tenantId: state.user.me.tenantId,
      });
      if (response.data.status === 201 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(
          slice.actions.setLegalEntityInfo({
            ...data,
            id,
          })
        );
        enqueueSnackbar('Company legal info added!', {
          variant: 'success',
          preventDuplicate: false,
        });
        return id;
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
      return false;
    }
  };
}

export function legalEntityUpdate(legalEntityId, data) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await companiesApi.legalEntityUpdate(legalEntityId, {
        ...data,
        tenantId: state.user.me.tenantId,
      });
      if (response.data.status === 200 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(
          slice.actions.updateLegalEntityInfo({
            ...data,
            id,
          })
        );
        return legalEntityId;
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
      return null;
    }
  };
}

export function legalEntityDelete(legalEntityId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.legalEntityDelete(legalEntityId);
      if (response.data.status === 204 && !response.data.errors?.length) {
        dispatch(slice.actions.deleteLegalEntityInfo(legalEntityId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Bank Account---------------------------------

export function bankAccountGet(bankAccountId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.bankAccountGet(bankAccountId);
      if (response.data.status === 200 && !response.data.errors?.length) {
        const data = response.data.body;
        dispatch(slice.actions.pushBankAccountsWithoutCompany(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function bankAccountCreate(data) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await companiesApi.bankAccountCreate({
        ...data,
        tenantId: state.user.me.tenantId,
      });
      if (response.data.status === 201 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(
          slice.actions.addInBankAccounts({
            id,
            ...data,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function bankAccountUpdate(bankAccountId, data) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await companiesApi.bankAccountUpdate(bankAccountId, {
        ...data,
        tenantId: state.user.me.tenantId,
      });
      if (response.data.status === 200 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(
          slice.actions.updateBankAccounts({
            id,
            ...data,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function bankAccountDelete(bankAccountId, bankName) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.bankAccountDelete(bankAccountId);
      if (response.data.status === 204 && !response.data.errors?.length) {
        dispatch(slice.actions.deleteBankAccount(bankAccountId));
        enqueueSnackbar(`${bankName} bank deleted!`, {
          variant: 'success',
          preventDuplicate: false,
        });
        return true;
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
      return false;
    }
  };
}

// ---------------Invoice---------------------------------

export function getInvoiceList(companyId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceList(companyId);
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.body.items;
        dispatch(slice.actions.setInvoiceList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function invoiceGet(invoiceIds) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceGet(invoiceIds);
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.body;
        dispatch(slice.actions.setOpenInvoice(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function invoiceCreate(fromId, toId) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await companiesApi.invoiceCreate({
        fromId,
        toId,
        tenantId: state.user.me.tenantId,
      });
      if (response.data.status === 201 && !response.data.errors?.length) {
        let id = response.data.body.id;
        dispatch(
          slice.actions.addInInvoiceList({
            id,
            fromId,
            toId,
          })
        );
        return true;
      } else {
        throw new Error(response.data.code.name);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
      return false;
    }
  };
}

export function invoiceUpdate({
  fromId,
  toId,
  bankAccountId,
  invoiceId,
  note,
  number,
  date}
) {
  return async (dispatch, getState) => {
    const state = getState();
    const data = {
      bankAccountId,
      fromId,
      toId,
      paymentNote:note,
      tenantId: state.user.me.tenantId,
    }

    try {
      debugger
      const response = await companiesApi.invoiceUpdate(invoiceId,
        data
      );
      if (response.data.status === 200 && !response.data.errors?.length) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.updateInvoice({
            id,
            fromId,
            toId,
            bankAccountId,
            note,
            number,
            date,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function invoiceDelete(invoiceId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceDelete(invoiceId);
      if (response.data.status === 200 && !response.data.errors?.length) {
        dispatch(slice.actions.deleteInvoice(invoiceId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function legalEntityGetArray(legalEntityId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.legalEntityGet(legalEntityId);
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.object.item;
        dispatch(slice.actions.setLegalEntityInfo(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function invoiceEntryGet(invoiceEntryIds) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceEntryGet(invoiceEntryIds);
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.object.items;
        dispatch(slice.actions.setInvoiceEntry(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: false,
      });
    }
  };
}

export function invoiceEntryCreate(
  invoiceId,
  description,
  encRate,
  encAmount,
  encPrice,
  orderIndex
) {
  return async (dispatch) => {
    try {
      // const response = await companiesApi.invoiceEntryCreate(invoiceId, description, encRate, encAmount, encPrice, orderIndex);
      const response = await companiesApi.invoiceEntryCreateEncrypt(
        invoiceId,
        description,
        encRate,
        encAmount,
        encPrice,
        orderIndex
      );
      if (response.data.status === 200 && !response.data.errors?.length) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.addInInvoiceEntry({
            id,
            invoiceId,
            description,
            encRate,
            encAmount,
            encPrice,
            orderIndex,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function invoiceEntryUpdate(
  invoiceEntryId,
  invoiceId,
  description,
  encRate,
  encAmount,
  encPrice,
  orderIndex
) {
  return async (dispatch) => {
    try {
      // const response = await companiesApi.invoiceEntryUpdate(invoiceEntryId, description, encRate, encAmount, encPrice, orderIndex);
      const response = await companiesApi.invoiceEntryUpdateEncrypt(
        invoiceEntryId,
        description,
        encRate,
        encAmount,
        encPrice,
        orderIndex
      );
      if (response.data.status === 200 && !response.data.errors?.length) {
        let id = response.data.object.item;
        dispatch(
          slice.actions.updateInvoiceEntry({
            id,
            invoiceId,
            description,
            encRate,
            encAmount,
            encPrice,
            orderIndex,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function invoiceEntryDelete(invoiceEntryId) {
  return async (dispatch) => {
    try {
      const response = await companiesApi.invoiceEntryDelete(invoiceEntryId);
      if (response.data.status === 200 && !response.data.errors?.length) {
        dispatch(slice.actions.deleteInvoiceEntry(invoiceEntryId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
