import axiosInstance, { sessionId, mode } from './axios';

// --------Member Performances Report----------
export const performancesReportApi = {
  getCurrentPeriod() {
    return axiosInstance
      .get(
        `memberReport/period/current${
          mode === 'development' ? '?sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
  memberReportList(periodFrom, periodTill) {
    return axiosInstance
      .get(
        `memberReport/list?periodFrom=${periodFrom}&periodTill=${periodTill}&fields=spareTimeHours,timeOffHours,workingHours&sort=spareTimeHours_desc${
          mode === 'development' ? '&sessionId=' + sessionId : ''
        }`
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (
          (error.response && error.response.data) || 'Something went wrong'
        );
      });
  },
};
