import { testsApi } from '../../api/testsApi';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  note: null,
};

const slice = createSlice({
  name: 'tests',
  initialState,
  reducers: {
    setNote(state, action) {
      state.note = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setNote } = slice.actions;

export function setMessgaes(msg) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await testsApi.setEncrypNote(msg);
      // console.log(response)
      // let data = response.data.object.item.types;
      // dispatch(slice.actions.setMessage(data));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}
export function getMessgaes() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await testsApi.getEncrypNote();
      // console.log(response)
      let data = response.data.object.item;
      dispatch(slice.actions.setNote(data));
    } catch (error) {
      // console.log(error)
      // dispatch(slice.actions.hasError(error));
    }
  };
}
