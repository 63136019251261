import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const AccessProtect = ({ children, access }) => {
  const { userAccesToTabsNew } = useSelector((state) => state.user);

  if (access && !userAccesToTabsNew?.includes(access)) {
    return <Redirect to="/404" />;
  }

  return children;
};

export default AccessProtect;
