import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { firebaseReducer } from 'react-redux-firebase';
// import { firestoreReducer } from 'redux-firestore';
import darkModeReducer from './slices/dark-mode';
import calendarReducer from './slices/calendar';
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
// import blogReducer from './slices/blog';
// import productReducer from './slices/product';
import userReducer from './slices/user';
import notificationsReducer from './slices/notifications';
import solutionsReducer from './slices/solutions';
import projectsReducer from './slices/projects';
import tests from './slices/tests';
import reports from './slices/reports';
import settings from './slices/settings';
import companies from './slices/companies';
import notesReducer from './slices/notes';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  version: 1,
  whitelist: ['theme'],
};

const productPersistConfig = {
  key: 'product',
  storage: storage,
  keyPrefix: 'redux-',
  blacklist: ['isLoading', 'error', 'products', 'product', 'filters'],
};

const rootReducer = combineReducers({
  theme: darkModeReducer,
  calendar: calendarReducer,
  user: userReducer,
  notifications: notificationsReducer,
  solutions: solutionsReducer,
  projects: projectsReducer,
  tests: tests,
  reports,
  settings,
  companies,
  notes: notesReducer,
});

export { rootPersistConfig, rootReducer };
