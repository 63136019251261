import React, { useState } from 'react';
import {
  Grid,
  Button,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import cryptoRandomString from 'crypto-random-string';
import KeysTable from './KeysTable';
import * as openpgp from 'openpgp';
import { LoadingButton } from '@material-ui/lab';

const FileSaver = require('file-saver');

const useStyles = makeStyles((theme) => ({
  root: {},
  margins: {
    '&>div': {
      marginBottom: '15px',
    },
  },

  input: {
    display: 'none',
  },
}));

const KeysManager = ({ setActiveKey }) => {
  const classes = useStyles();
  const [keys, setKeys] = useState([]);
  const [currentKey, setCurrentKey] = useState();
  const [isGenereting, setIsGenereting] = useState(false);

  const generateNewKey = async () => {
    setIsGenereting(true);
    const key = await openpgp.generateKey({
      type: 'ecc', // Type of the key, defaults to ECC
      curve: 'curve25519', // ECC curve name, defaults to curve25519
      userIds: [{ name: 'John Smith', email: 'john@example.com' }], // you can pass multiple user IDs
      passphrase: 'super long and hard to guess secret', // protects the private key
    });
    // console.log(key);
    setIsGenereting(false);
    setKeys([
      ...keys,
      {
        id: cryptoRandomString({ length: 7 }),
        private: key.privateKeyArmored,
        public: key.publicKeyArmored,
        name: '',
        default: false,
      },
    ]);
    // return [key.publicKeyArmored, key.privateKeyArmored];
  };

  const saveKey = (keyItem) => {
    const key = [
      {
        id: keyItem.id,
        private: keyItem.private,
        public: keyItem.public,
        name: keyItem.name,
        default: keyItem.default,
      },
    ];
    const keysToJson = JSON.stringify(key);
    const blob = new Blob([keysToJson], { type: 'application/json' });
    FileSaver.saveAs(blob, 'pgp_key.json');
  };

  const saveAllKeys = () => {
    let keysArray = [];

    keys.forEach((item) => {
      keysArray = [
        ...keysArray,
        {
          id: item.id,
          private: item.private,
          public: item.public,
          name: item.name,
          default: item.default,
        },
      ];
    });

    const keysToJson = JSON.stringify(keys);
    const blob = new Blob([keysToJson], { type: 'application/json' });

    FileSaver.saveAs(blob, 'pgp_key.json');
  };

  function handleUpload(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      const reader = new FileReader();
      reader.onload = function (evt) {
        const string = evt.target.result;
        const keysArray = JSON.parse(string);

        const newKeys = keysArray.map((item) => {
          const isDefaultInKeys = keys.some((item) => item.default === true);
          // console.log(isDefaultInKeys)
          if (!isDefaultInKeys) {
            if (item.default) {
              setCurrentKey(item.id);
              selectKey(item);
              setActiveKey(item.id);
            }
            return item;
          } else {
            return { ...item, default: false };
          }
        });
        setKeys([...keys, ...newKeys]);
      };
      reader.readAsText(event.target.files[i]);
    }
  }

  const selectKey = (key) => {
    localStorage.setItem(
      'pgp_private',
      JSON.stringify(key.id + '_' + key.private)
    );
    localStorage.setItem(
      'pgp_public',
      JSON.stringify(key.id + '_' + key.public)
    );
    localStorage.setItem('keyDate', JSON.stringify(new Date()));
    setCurrentKey(key.id);
    setActiveKey(key.id);
  };

  const handleChangeKey = (e, item, fieldName) => {
    const newKeys = keys.map((key) => {
      if (key.id === item.id) {
        if (fieldName === 'name') return { ...key, name: e.target.value };
        if (fieldName === 'default') return { ...key, default: true };
      } else if (fieldName === 'default') return { ...key, default: false };
      return key;
    });
    setKeys(newKeys);
  };

  const handleDeleteKey = (item) => {
    const newKeys = keys.filter((key) => key.id !== item.id);
    setKeys(newKeys);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item className={classes.uploadButton} sx={{ mb: 1 }}>
          <input
            accept="application/JSON"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="secondary" component="span">
              Upload keys
            </Button>
          </label>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {keys.length > 0 && (
          <Grid item sx={{ width: '100%', mb: 2, mt: 2 }}>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Default</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keys.map((item) => {
                    return (
                      <KeysTable
                        key={item.id}
                        item={item}
                        saveKey={saveKey}
                        selectKey={selectKey}
                        handleChangeKey={handleChangeKey}
                        handleDeleteKey={handleDeleteKey}
                        currentKey={currentKey}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          // sx={{ mb: 1 }}
        >
          <LoadingButton
            variant="contained"
            color="secondary"
            // pendingPosition="start"
            pending={isGenereting}
            onClick={generateNewKey}
          >
            Generate new key
          </LoadingButton>
          {keys.length > 1 && (
            <Button
              variant="contained"
              component="span"
              sx={{ ml: 2 }}
              onClick={saveAllKeys}
            >
              Save all keys
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default KeysManager;
