import {
  Button,
  makeStyles,
  Radio,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles({
  hideLastBorder: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  currentKey: {
    background: '#d7fddfb2',
  },
});

const KeysTable = ({
  saveKey,
  selectKey,
  item,
  handleChangeKey,
  handleDeleteKey,
  currentKey,
}) => {
  const classes = useStyles();

  return (
    <TableRow
      className={clsx(
        classes.hideLastBorder,
        currentKey === item.id && classes.currentKey
      )}
    >
      <TableCell component="th" scope="row">
        {item.id}
      </TableCell>
      <TableCell align="center">
        <TextField
          fullWidth
          size="small"
          value={item.name}
          onChange={(e) => {
            handleChangeKey(e, item, 'name');
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Radio
          checked={item.default}
          onChange={(e) => {
            handleChangeKey(e, item, 'default');
          }}
          value={item.id}
          name="radio-buttons"
        />
      </TableCell>
      <TableCell align="center">
        <Button
          variant="contained"
          size="small"
          component="span"
          onClick={() => saveKey(item)}
        >
          Export
        </Button>
        <Button
          variant="contained"
          color="inherit"
          size="small"
          component="span"
          sx={{ ml: 1 }}
          onClick={() => selectKey(item)}
        >
          Current
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          component="span"
          sx={{ ml: 1 }}
          onClick={() => handleDeleteKey(item)}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default KeysTable;
