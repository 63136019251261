import { settingsApi } from '../../api/settingsApi';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  error: false,
  countryList: null,
  countryPagination: {
    offset: 0,
    per_page: 50,
    total: 1,
  },
  currencyList: null,
  bankList: null,
  positionsList: null,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // START LOADING
    startPositionsListLoading(state) {
      state.isPositionsListLoading = true;
    },

    hasError(state, action) {
      state.error = action.payload;
    },
    //country
    setCountryList(state, action) {
      state.countryList = action.payload;
    },
    setCountryPagination(state, action) {
      state.countryPagination = action.payload;
    },
    addInCountryList(state, action) {
      state.countryList = [...state.countryList, action.payload];
    },
    updateCountry(state, action) {
      const newCountry = action.payload;
      state.countryList = state.countryList.map((item) => {
        if (item.id === newCountry.id)
          return { ...item, name: newCountry.name, code: newCountry.code };
        return item;
      });
    },
    deleteCountry(state, action) {
      const id = action.payload;
      state.countryList = state.countryList.filter((item) => item.id !== id);
    },
    //currency
    setCurrencyList(state, action) {
      state.currencyList = action.payload;
    },
    addInCurrencyList(state, action) {
      state.currencyList = [...state.currencyList, action.payload];
    },

    updateCurrency(state, action) {
      const newCurrency = action.payload;
      state.currencyList = state.currencyList.map((item) => {
        if (item.id === newCurrency.id) {
          return { ...item, code: newCurrency.code };
        }

        return item;
      });
    },

    deleteCurrency(state, action) {
      const id = action.payload;
      state.currencyList = state.currencyList.filter((item) => item.id !== id);
    },
    //bank
    setBankList(state, action) {
      state.bankList = action.payload;
    },
    addInBankList(state, action) {
      state.bankList = [...state.bankList, action.payload];
    },
    updateBank(state, action) {
      const newBank = action.payload;
      state.bankList = state.bankList.map((item) => {
        if (item.id === newBank.id)
          return { ...item, ...newBank };
        return item;
      });
    },
    deleteBank(state, action) {
      const id = action.payload;
      state.bankList = state.bankList.filter((item) => item.id !== id);
    },
    //positions
    setPositionsList(state, action) {
      state.isPositionsListLoading = false;
      state.positionsList = action.payload;
    },
    addInPositionsList(state, action) {
      state.positionsList = [...state.positionsList, action.payload];
    },
    updatePosition(state, action) {
      const newPosition = action.payload;
      state.positionsList = state.positionsList.map((item) => {
        if (item.id === newPosition.id)
          return {
            ...item,
            name: newPosition.name,
            typeOfWork: newPosition.typeOfWork,
          };
        return item;
      });
    },
    deletePosition(state, action) {
      const id = action.payload;
      state.positionsList = state.positionsList.filter(
        (item) => item.id !== id
      );
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { setReport, setReportTotal } = slice.actions;

// ---------------Country---------------------------------

export function getCountryList({ page, per_page }) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.countryList({ page, per_page });
      if (response.data.status === 200 && !response.data.errors?.length) {
        const { items, offset, per_page, total } = response.data.body;
        dispatch(slice.actions.setCountryList(items));
        dispatch(
          slice.actions.setCountryPagination({
            offset,
            per_page,
            total,
          })
        );
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//delete after backend make search by text
export function getAllCountryList() {
  return async (dispatch) => {
    try {
      const response = await settingsApi.countryList({ per_page: 250 });
      if (response.data.status === 200 && !response.data.errors?.length) {
        const { items } = response.data.body;
        dispatch(slice.actions.setCountryList(items));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function countyCreate(name, code) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await settingsApi.countryCreate(
        name,
        code,
        state.user.me.tenantId
      );
      if (response.data.status === 201 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(slice.actions.addInCountryList({ id, name, code }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function countryDelete(countryId) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.countryDelete(countryId);
      if (response.data.status === 204 && !response.data.errors?.length) {
        dispatch(slice.actions.deleteCountry(countryId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function countryUpdate(countryId, name, code) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await settingsApi.countryUpdate(
        countryId,
        name,
        code,
        state.user.me.tenantId
      );
      if (response.data.status === 200 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(slice.actions.updateCountry({ id, name, code }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Currency---------------------------------

export function getCurrencyList() {
  return async (dispatch) => {
    try {
      const response = await settingsApi.currencyList();
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.object.items;
        dispatch(slice.actions.setCurrencyList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//delete after backend make search by text
export function getAllCurrencyList() {
  return async (dispatch) => {
    try {
      const response = await settingsApi.currencyList({ per_page: 250 });
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.body.items;
        dispatch(slice.actions.setCurrencyList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function currencyCreate(code) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await settingsApi.currencyCreate(
        code,
        state.user.me.tenantId
      );
      if (response.data.status === 201 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(slice.actions.addInCurrencyList({ id, code }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function currencyUpdate(id, code) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await settingsApi.currencyUpdate(
        id,
        code,
        state.user.me.tenantId
      );
      if (response.data.status === 200 && !response.data.errors?.length) {
        dispatch(slice.actions.updateCurrency({ id, code }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function currencyDelete(currencyId) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.currencyDelete(currencyId);
      if (response.data.status === 204 && !response.data.errors?.length) {
        dispatch(slice.actions.deleteCurrency(currencyId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Bank---------------------------------

export function getBankList() {
  return async (dispatch) => {
    try {
      const response = await settingsApi.bankList();
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.body.items;
        dispatch(slice.actions.setBankList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//delete after backend make search by text
export function getAllBankList() {
  return async (dispatch) => {
    try {
      const response = await settingsApi.bankList({ per_page: 250 });
      if (response.data.status === 200 && !response.data.errors?.length) {
        let data = response.data.body.items;
        dispatch(slice.actions.setBankList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bankCreate(data) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await settingsApi.bankCreate({
        ...data,
        tenantId: state.user.me.tenantId,
      });
      if (response.data.status === 201 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(slice.actions.addInBankList({ id, ...data }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bankUpdate(bankId, data) {
  return async (dispatch, getState) => {
    const state = getState();

    const dataWithTenant = {
      ...data,
      tenantId: state.user.me.tenantId,
    };
    try {
      const response = await settingsApi.bankUpdate(bankId, dataWithTenant);
      if (response.data.status === 200 && !response.data.errors?.length) {
        dispatch(slice.actions.updateBank({ id: bankId, ...dataWithTenant }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bankDelete(bankId) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.bankDelete(bankId);
      if (response.data.status === 204 && !response.data.errors?.length) {
        dispatch(slice.actions.deleteBank(bankId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------Positions---------------------------------

export function getPositionList() {
  return async (dispatch) => {
    dispatch(slice.actions.startPositionsListLoading());
    try {
      const response = await settingsApi.positionListNew();
      if (response.data.status === 200 && response.data.errors === null) {
        let data = response.data.body.items;
        dispatch(slice.actions.setPositionsList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//delete after backend make search by text
export function getAllPositionList() {
  return async (dispatch) => {
    dispatch(slice.actions.startPositionsListLoading());
    try {
      const response = await settingsApi.positionListNew({ per_page: 250 });
      if (response.data.status === 200 && response.data.errors === null) {
        let data = response.data.body.items;
        dispatch(slice.actions.setPositionsList(data));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function positionCreate(name, typeOfWork) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await settingsApi.positionCreate({
        name,
        typeOfWork,
        tenantId: state.user.me.tenantId,
      });
      if (response.data.status === 201 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(slice.actions.addInPositionsList({ id, name, typeOfWork }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function positionUpdate(positionId, name, typeOfWork) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await settingsApi.positionUpdate(positionId, {
        name,
        typeOfWork,
        tenantId: state.user.me.tenantId,
      });
      if (response.data.status === 200 && !response.data.errors?.length) {
        const id = response.data.body.id;
        dispatch(slice.actions.updatePosition({ id, name, typeOfWork }));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function positionDelete(positionId) {
  return async (dispatch) => {
    try {
      const response = await settingsApi.positionDelete(positionId);
      if (response.data.status === 204 && !response.data.errors?.length) {
        dispatch(slice.actions.deletePosition(positionId));
      } else throw new Error(response.data.code.name);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
