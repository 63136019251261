import React from 'react';
import './App.scss';
import routes, { renderRoutes } from './routes';
// import { createBrowserHistory } from 'history';
import NotistackProvider from './components/NotistackProvider';
import ThemeConfig from './theme';
import { BrowserRouter } from 'react-router-dom';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

// const history = createBrowserHistory();

function App() {
  return (
    <ThemeConfig>
      {/* <Router history={history}> */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <NotistackProvider>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            {/* <FirebaseProvider> */}
            {renderRoutes(routes)}
            {/* </FirebaseProvider> */}
          </BrowserRouter>
        </NotistackProvider>
      </LocalizationProvider>
      {/* </Router> */}
    </ThemeConfig>
  );
}

export default App;
