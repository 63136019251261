import axios from 'axios';

// ----------------------------------------------------------------------

export const apiUrl = process.env.REACT_APP_API_URL;

export const baseURL = apiUrl + '/api/v1';

export const sessionId = process.env.REACT_APP_SESSION_ID;

export const mode = process.env.NODE_ENV;

export const authorizationUrl = process.env.REACT_APP_AUTHORIZATION_URL;

export const signInUrl = apiUrl + '/sign-in';

export const signOutUrl = apiUrl + '/sign-out';

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL,
});

export const axiosNewInstance = axios.create({
  withCredentials: true,
  baseURL,
});

axiosNewInstance.interceptors.response.use(
  (response) => {
    if (response.data.status_code === 'NOT_AUTHORIZED')
      window.location = authorizationUrl;
    else return response;
  },
  (error) => {
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    );
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.code && response.data.code.name === 'UNAUTHORIZED')
      window.location = authorizationUrl;
    else return response;
  },
  (error) => {
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    );
  }
);

export default axiosInstance;
